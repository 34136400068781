import React from "react";
import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Login } from './components/pages/login';
import  Cart  from './components/pages/Cart';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

import { Register } from './components/pages/register';
import  Home  from './components/pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Men from './components/pages/Men';
import Women from './components/pages/Women';
import Sunglasses from './components/pages/Sunglasses';
import Eyeglasses from './components/pages/Eyeglasses';
import {Map} from './components/pages/map';
import aboutUs from './components/pages/aboutUs';
import Policies from './components/pages/Policies';
import Collection from './components/pages/Collection';
import Bestsellers from './components/pages/Bestsellers';
import Profile from './components/pages/Profile';
import Settings from './components/pages/Settings';
import Orders from './components/pages/Orders';
import GlassesCard from "./components/pages/GlassesCard";
import SearchBar from "./components/SearchBar";
// import MainPage from './components/pages/Admin/main-page'
import CategoriesPage from './components/pages/Admin/categories-page'

function App() {
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  // const handleLogin = () => {
  //   setIsLoggedIn(true);
  // };

  // const handleLogout = () => {
  //   setIsLoggedIn(false);
  // };

  return (
    <>
    <hr className="navbar-line" />
      <Router>
      
        <Navbar />
        
        <hr className="navbar-line" />
        
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/women' element={<Women/>} />
          <Route path='/men' element={<Men/>} />
          <Route path='/eyeglasses' element={<Eyeglasses/>} />
          <Route path='/sunglasses' element={<Sunglasses/>} />
          <Route path='/map' element={<Map/>} />
          <Route path='/login' element={<Login/>} />
          <Route path='/register' element={<Register/>} />
          <Route path='/aboutUs' element={<aboutUs/>} />
          <Route path='/policies' element={<Policies/>} />
          <Route path='/collection' element={<Collection/>} />
          <Route path='/bestsellers' element={<Bestsellers/>} />
          <Route path='/cart' element={<Cart/>} />
          <Route path='/profile' element={<Profile/>} />
          <Route path='/orders' element={<Orders/>} />
          <Route path='/settings' element={<Settings/>} />
          <Route path='/admin' element={<CategoriesPage/>}/>
          <Route path='/glassesCard' element={<GlassesCard/>} />
          <Route path='/searchBar' element={<SearchBar/>} />
        </Routes>

      <Footer />
      </Router>
    </>
  );
}

export default App;
