import React, {useState} from 'react';

function SearchBar() {
    const searchBar = () => {}
    const [searchInput, setSearchInput] = useState("");

    
    const handleChange = (e) => {
        e.preventDefault();
        setSearchInput(e.target.value);
      };
      
      
      if (searchInput.length > 0) {
          glasses.filter((glassesFilter) => {
          return glasses.name.match(searchInput);
      });
      }

      const glasses =[];
      
    {/*const glasses = [
        {name: "5003", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "5003", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "5003", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "5003", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "5003", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "5003", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "5003", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "5003", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "80515", price: "", rim: "", shape: "", size: "", gender: "men", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "DC9013", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "DC9013", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "DC9013", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "EDJJI", price: "", rim: "", shape: "", size: "", gender: "women", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "FF0318", price: "", rim: "", shape: "", size: "", gender: "women", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-1010", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-1014", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-1074", price: "", rim: "", shape: "", size: "", gender: "women", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-1074", price: "", rim: "", shape: "", size: "", gender: "women", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-1074", price: "", rim: "", shape: "", size: "", gender: "women", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-1074", price: "", rim: "", shape: "", size: "", gender: "women", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-15033", price: "", rim: "", shape: "", size: "", gender: "men", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-2146", price: "", rim: "", shape: "", size: "", gender: "women", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-7805", price: "", rim: "", shape: "", size: "", gender: "women", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-7805", price: "", rim: "", shape: "", size: "", gender: "women", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-7805", price: "", rim: "", shape: "", size: "", gender: "women", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-7805", price: "", rim: "", shape: "", size: "", gender: "women", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-8009", price: "", rim: "", shape: "", size: "", gender: "men", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-8009", price: "", rim: "", shape: "", size: "", gender: "men", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-8026", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-8026", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-8049", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-8107", price: "", rim: "", shape: "", size: "", gender: "men", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-8107", price: "", rim: "", shape: "", size: "", gender: "men", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-8107", price: "", rim: "", shape: "", size: "", gender: "men", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-8109", price: "", rim: "", shape: "", size: "", gender: "men", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-8109", price: "", rim: "", shape: "", size: "", gender: "men", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-8625", price: "", rim: "", shape: "", size: "", gender: "men", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-8625", price: "", rim: "", shape: "", size: "", gender: "men", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-8858", price: "", rim: "", shape: "", size: "", gender: "women", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-9283", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-9283", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-9283", price: "", rim: "", shape: "", size: "", gender: "unisex", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-9283", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-L021", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-L021", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-L021", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-L021", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-L12", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-L12", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-L12", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-L12", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-L12", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-LV9312", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-LV9312", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-LV9312", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-M02", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-M02", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-M02", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-M02", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-M57", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-M57", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-M57", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MC-M57", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MI-Square", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-007", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-007", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-007", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-007", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-007", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-509", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-509", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-509", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-509", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-513", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-513", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-513", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-513", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-517", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-517", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-517", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-520", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-520", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-520", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-522", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-522", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-522", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-526", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-526", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-526", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-526", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-527", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-527", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-529", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-530", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-530", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-530", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-530", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-532", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-532", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-532", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-541", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-541", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-548", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-548", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-548", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-548", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-549", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-549", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-549", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-549", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-551", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-551", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-551", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-551", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-552", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-552", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-552", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-552", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-560", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-560", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-560", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-560", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-69106", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-69106", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-69106", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-C9037", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-C9037", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-C9037", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-C9058", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-M007", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-M007", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-M008", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-M008", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-M008", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-M008", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-M008", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "MT-M008", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "SunOverGlass", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""},
        {name: "TF5371", price: "", rim: "", shape: "", size: "", gender: "", colors: "", material: "", lensWidth: "", bridge: "", temple: "", bSize: "", faceWidth: "", faceShape: "", features: ""}
    ]; */}

    return (
        <div>

        <input
           type="text"
           placeholder="Search here"
           onChange={handleChange}
           value={searchInput} />
        
        <table>
          <tr>
            <th>Search terms: </th>
            <th>name, </th>
            <th>price, </th>
            <th>rim, </th>
            <th>shape, </th>
            <th>size, </th>
            <th>gender, </th>
            <th>colors, </th>
            <th>material, </th>
            <th>lensWidth, </th>
            <th>bridge, </th>
            <th>temple, </th>
            <th>bSize, </th>
            <th>faceWidth, </th>
            <th>faceShape, </th>
            <th>features </th>        
          </tr>
        
        {glasses.map((glassesFilter, index) => {
          <tr>
            <td>{glasses.name}</td>
            <td>{glasses.price}</td>
            <td>{glasses.rim}</td>
            <td>{glasses.shape}</td>
            <td>{glasses.size}</td>
            <td>{glasses.gender}</td>
            <td>{glasses.colors}</td>
            <td>{glasses.material}</td>
            <td>{glasses.lensWidth}</td>
            <td>{glasses.bridge}</td>
            <td>{glasses.temple}</td>
            <td>{glasses.bSize}</td>
            <td>{glasses.faceWidth}</td>
            <td>{glasses.faceShape}</td>
            <td>{glasses.features}</td>  
          </tr>
        })}

        </table>
        
        </div>
    );
  };

  export default SearchBar;