import React from 'react';
import '../App.css';
import './MenHeader.css';

function MenHeader() {
  return (
    <div className='men-container'>
      <h1>Men</h1>
    </div>
  );
}

export default MenHeader;