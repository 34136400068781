import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Filter from '../Filter';
import EyeglassesHeader from '../EyeglassesHeader';

export default function Eyeglasses() {
  return (
    <div>
      <>
      <EyeglassesHeader />
      <Filter />
      </> 
    </div>
  );
}