import React from 'react';
import '../App.css';
import './SunglassesHeader.css';

function SunglassesHeader() {
  return (
    <div className='sunglasses-header'>
      <h1>Sunglasses</h1>
    </div>
  );
}

export default SunglassesHeader;