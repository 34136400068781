import React from 'react';
import '../../App.css';
import '../GlassesCard.css';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

function GlassesCard() {
    return (
      <div>
        <>
        <div class='row-fluid'>
            <div class="span12 breadcrumbs-grid">
                <div id="breadcrumbs_10" class="bc-loc">
                    <div class="ty-breadcrumbs product-breadcrumbs clearfix">
                        <div class="inner-bcr">
                            <Link to='/' class="ty-breadcrumbs__a">Home</Link>
                            <span class="ty-breadcrumbs__slash">/</span>
                            <Link to='/men' class="ty-breadcrumbs__a">Men</Link>
                            <span class="ty-breadcrumbs__slash">/</span>
                            <span class="ty-breadcrumbs__current">Midas-CS8303</span>
                        </div>
                        <h1 class="product-h1">Midas-CS8303</h1>	
                        <div class="ty-product-switcher">
	                        <a class="ty-product-switcher__a ty-product-switcher__a-left disabled">
                                <i class="ty-product-switcher__icon ty-icon-left-circle"></i>
                            </a>
	                        <span class="switcher-selected-product">1</span>
	                        <span>of</span>
	                        <span class="switcher-total">243</span>
	                        <a class="ty-product-switcher__a ty-product-switcher__a-right " href="https://tnbglasses.com/eyeglasses/midas-ct0072/" title="Next"><i class="ty-product-switcher__icon ty-icon-right-circle"></i></a>
	                    </div>

                    </div>
                    {/* <!--breadcrumbs_10--> */}
                </div>
                <Link class="ty-product-switcher__a ty-product-switcher__a-right " to="/" title="Next">
                    <i class="ty-product-switcher__icon ty-icon-right-circle"></i>
                </Link>
            </div> 
        </div>

        <div class='row-fluid'>
            <div class="span9 main-content-grid product-data-block">
                {/* <!-- Inline script moved to the bottom of the page --> */}
                <div class="ty-product-block ty-product-detail">
                    <div class="ty-product-block__wrapper clearfix">
                        <div itemscope="" itemtype="https://schema.org/Product">
                            <meta itemprop="sku" content="CS8303-C1" />
                            <meta itemprop="name" content="Midas-CS8303" />
                            <meta itemprop="description" content="The Midas clipon unisex eyeglasses are made from high-quality 
                            acetate and feature a timeless design and round shape that is perfect for any occasion! Clipon 
                            eyeglasses are a great addition to any wardrobe for men and women. They can be used for everyday w" />

                            <div itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
                                <link itemprop="availability" href="https://schema.org/InStock" />
                                <meta itemprop="priceCurrency" content="CAD" />
                                <meta itemprop="price" content="299" />
                            </div>
                        </div>
                        <div class="ty-product-block__img-wrapper">
                            <div class="ty-product-block__img cm-reload-607" id="product_images_607_update">
                                <span class="ty-discount-label cm-reload-607" id="discount_label_update_607">
                                    <span class="ty-discount-label__item" id="line_prc_discount_value_607">
                                        <span class="ty-discount-label__value" id="prc_discount_value_label_607"> Save 25%</span>
                                    </span>
                                    {/* <!--discount_label_update_607--> */}
                                </span>  
                                <div class="ty-product-img cm-preview-wrapper">
                                    <a id="det_img_link_60764fa3bf5e6241_37635" data-ca-image-id="preview[product_images_60764fa3bf5e6241]" class="cm-image-previewer cm-previewer ty-previewer" data-ca-image-width="3256" data-ca-image-height="1656" href="https://tnbglasses.com/images/detailed/37/MC-CS8303-C1_2.jpg" title="">    
                                        <img class="ty-pict     cm-image" id="det_img_60764fa3bf5e6241_37635" src="https://tnbglasses.com/images/thumbnails/880/447/detailed/37/MC-CS8303-C1_2.jpg" alt="" title="" data-cloudzoom="zoomImage: &quot;https://tnbglasses.com/images/detailed/37/MC-CS8303-C1_2.jpg&quot;" style={{"user-select": "none;"}} />
                                        <span class="ty-previewer__icon hidden-phone"></span>
                                    </a>

                                    <a id="det_img_link_60764fa3bf5e6241_37636" data-ca-image-id="preview[product_images_60764fa3bf5e6241]" class="cm-image-previewer hidden cm-previewer ty-previewer" data-ca-image-width="3288" data-ca-image-height="1715" href="https://tnbglasses.com/images/detailed/37/MC-CS8303-C1.jpg" title="">    
                                        <img class="ty-pict     cm-image" id="det_img_60764fa3bf5e6241_37636" src="https://tnbglasses.com/images/thumbnails/880/459/detailed/37/MC-CS8303-C1.jpg" alt="" title="" data-cloudzoom="zoomImage: &quot;https://tnbglasses.com/images/detailed/37/MC-CS8303-C1.jpg&quot;" />
                                        <span class="ty-previewer__icon hidden-phone"></span>
                                    </a>
                                
                                    <a id="det_img_link_60764fa3bf5e6241_37637" data-ca-image-id="preview[product_images_60764fa3bf5e6241]" class="cm-image-previewer hidden cm-previewer ty-previewer" data-ca-image-width="2934" data-ca-image-height="1177" href="https://tnbglasses.com/images/detailed/37/MC-CS8303-C1_2_4dsz-ws.jpg" title="">    
                                        <img class="ty-pict     cm-image" id="det_img_60764fa3bf5e6241_37637" src="https://tnbglasses.com/images/thumbnails/880/353/detailed/37/MC-CS8303-C1_2_4dsz-ws.jpg" alt="" title="" data-cloudzoom="zoomImage: &quot;https://tnbglasses.com/images/detailed/37/MC-CS8303-C1_2_4dsz-ws.jpg&quot;" />
                                        <span class="ty-previewer__icon hidden-phone"></span></a>
                                        <a id="det_img_link_60764fa3bf5e6241_37638" data-ca-image-id="preview[product_images_60764fa3bf5e6241]" class="cm-image-previewer hidden cm-previewer ty-previewer" data-ca-image-width="3033" data-ca-image-height="1247" href="https://tnbglasses.com/images/detailed/37/MC-CS8303-C1_3.jpg" title="">    
                                        <img class="ty-pict     cm-image" id="det_img_60764fa3bf5e6241_37638" src="https://tnbglasses.com/images/thumbnails/880/361/detailed/37/MC-CS8303-C1_3.jpg" alt="" title="" data-cloudzoom="zoomImage: &quot;https://tnbglasses.com/images/detailed/37/MC-CS8303-C1_3.jpg&quot;" />
                                        <span class="ty-previewer__icon hidden-phone"></span>
                                    </a>
                                    
                                    <a id="det_img_link_60764fa3bf5e6241_37639" data-ca-image-id="preview[product_images_60764fa3bf5e6241]" class="cm-image-previewer hidden cm-previewer ty-previewer" data-ca-image-width="3216" data-ca-image-height="1360" href="https://tnbglasses.com/images/detailed/37/MC-CS8303-C1_2_2.jpg" title="">    
                                        <img class="ty-pict     cm-image" id="det_img_60764fa3bf5e6241_37639" src="https://tnbglasses.com/images/thumbnails/880/372/detailed/37/MC-CS8303-C1_2_2.jpg" alt="" title="" data-cloudzoom="zoomImage: &quot;https://tnbglasses.com/images/detailed/37/MC-CS8303-C1_2_2.jpg&quot;" />
                                        <span class="ty-previewer__icon hidden-phone"></span>
                                    </a>
                                </div>

                                <input type="hidden" name="no_cache" value="1" />
                                <div class="ty-product-bigpicture-thumbnails_gallery">
                                    <div class="cm-image-gallery-wrapper ty-thumbnails_gallery ty-inline-block" style={{"width": "125.333px;"}}>
                                        <div class="ty-product-thumbnails owl-carousel cm-image-gallery owl-more-theme" id="images_preview_60764fa3bf5e6241" style={{"opacity": "1;", "display": "block;", "visibility": "visible;", "position": "relative;"}}>
                                            <div class="owl-wrapper-outer"><div class="owl-wrapper" style={{"width": "210px;", "left": "0px;", "display": "block;"}}><div class="owl-item" style={{"width": "42px;"}}>
                                                <div class="cm-item-gallery ty-float-left">
                                                    <a data-ca-gallery-large-id="det_img_link_60764fa3bf5e6241_37635" class="cm-gallery-item cm-thumbnails-mini active ty-product-thumbnails__item" style={{"width": "35px"}}>    
                                                        <img class="ty-pict     cm-image" id="det_img_60764fa3bf5e6241_37635_mini" src="https://tnbglasses.com/images/thumbnails/35/35/detailed/37/MC-CS8303-C1_2.jpg" alt="" title=""/>
                                                    </a>
                                                </div>
                                            </div>
                                            
                                            <div class="owl-item" style={{"width": "42px;"}}>
                                                <div class="cm-item-gallery ty-float-left">
                                                    <a data-ca-gallery-large-id="det_img_link_60764fa3bf5e6241_37636" class="cm-gallery-item cm-thumbnails-mini ty-product-thumbnails__item">    
                                                        <img class="ty-pict     cm-image" id="det_img_60764fa3bf5e6241_37636_mini" src="https://tnbglasses.com/images/thumbnails/35/35/detailed/37/MC-CS8303-C1.jpg" alt="" title=""/>
                                                    </a>
                                                </div>
                                            </div>
                                            
                                            <div class="owl-item" style={{"width": "42px;"}}>
                                                <div class="cm-item-gallery ty-float-left">
                                                    <a data-ca-gallery-large-id="det_img_link_60764fa3bf5e6241_37637" class="cm-gallery-item cm-thumbnails-mini ty-product-thumbnails__item">    
                                                        <img class="ty-pict     cm-image" id="det_img_60764fa3bf5e6241_37637_mini" src="https://tnbglasses.com/images/thumbnails/35/35/detailed/37/MC-CS8303-C1_2_4dsz-ws.jpg" alt="" title=""/>
                                                    </a>
                                                </div>
                                            </div>
                                            
                                            <div class="owl-item" style={{"width": "42px;"}}>
                                                <div class="cm-item-gallery ty-float-left">
                                                    <a data-ca-gallery-large-id="det_img_link_60764fa3bf5e6241_37638" class="cm-gallery-item cm-thumbnails-mini ty-product-thumbnails__item">    
                                                        <img class="ty-pict     cm-image" id="det_img_60764fa3bf5e6241_37638_mini" src="https://tnbglasses.com/images/thumbnails/35/35/detailed/37/MC-CS8303-C1_3.jpg" alt="" title=""/>
                                                    </a>
                                                </div>
                                            </div>
                                            
                                            <div class="owl-item" style={{"width": "42px;"}}>
                                                <div class="cm-item-gallery ty-float-left">
                                                    <a data-ca-gallery-large-id="det_img_link_60764fa3bf5e6241_37639" class="cm-gallery-item cm-thumbnails-mini ty-product-thumbnails__item">    
                                                        <img class="ty-pict     cm-image" id="det_img_60764fa3bf5e6241_37639_mini" src="https://tnbglasses.com/images/thumbnails/35/35/detailed/37/MC-CS8303-C1_2_2.jpg" alt="" title=""/>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="owl-controls clickable">
                                        <div class="owl-buttons">
                                            <div class="owl-prev">
                                                <i class="icon-left-open-thin ty-icon-left-open-thin"></i>
                                            </div>
                                        
                                        <div class="owl-next">
                                            <i class="icon-right-open-thin ty-icon-right-open-thin"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                {/*<!-- Inline script moved to the bottom of the page -->

                <!--product_images_607_update--> */}
            </div>                     
        </div>
        
        <div class="ty-product-block-tab-info">
            <div class="ty-product-block__left ty-product-details-det info-in-tabs">
            {/* <!-- Inline script moved to the bottom of the page --> */}
                <div class="ty-tabs cm-j-tabs clearfix">
                    <ul class="ty-tabs__list">
                        <li id="features" class="ty-tabs__item cm-js active"> <a class="ty-tabs__a">Features</a></li>
                        <li id="description" class="ty-tabs__item cm-js"><a class="ty-tabs__a">Description</a></li>
                        <li id="discussion" class="ty-tabs__item cm-js"><a class="ty-tabs__a">Reviews</a></li>
                    </ul>
                </div>

                <div class="cm-tabs-content ty-tabs__content clearfix" id="tabs_content">
                    <div id="content_features" class="ty-wysiwyg-content content-features" style={{"display": "block;"}}>                                                  
                        <div class="ty-product-feature">
                            <span class="ty-product-feature__label">Brand:</span>                           
                            <div class="ty-product-feature__value">Midas</div>
                        </div>
                    
                    <div class="ty-product-feature">
                        <span class="ty-product-feature__label">Frame Material
                        <span class="ty-help-info">        
                            <a id="opener_content_3_notes" class="cm-dialog-opener cm-dialog-auto-size " data-ca-target-id="content_content_3_notes" rel="nofollow">
                                <span>
                                    <span class="ty-tooltip-block">
                                        <i class="ty-icon-help-circle"></i>
                                    </span>
                                </span>
                            </a>

                        <div class="hidden ty-wysiwyg-content" id="content_content_3_notes" title="Frame Material">
                            <p>Frame Material help</p>
                        </div>

                        </span>
                        :
                        </span>
                   
                        <div class="ty-product-feature__value">
                            <ul class="ty-product-feature__multiple">
                                <li class="ty-product-feature__multiple-item">
                                    <span class="ty-compare-checkbox" title="Acetate">
                                        <i class="ty-compare-checkbox__icon ty-icon-ok"></i>
                                    </span>
                                    <span class="ty-product-feature__prefix"></span>Acetate<span class="ty-product-feature__suffix"></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                
                    <div class="ty-product-feature">
                        <span class="ty-product-feature__label">Gender:</span>                      
                        <div class="ty-product-feature__value">
                            <ul class="ty-product-feature__multiple">
                                <li class="ty-product-feature__multiple-item">
                                    <span class="ty-compare-checkbox" title="Men">
                                        <i class="ty-compare-checkbox__icon ty-icon-ok"></i>
                                    </span>
                                    <span class="ty-product-feature__prefix"></span>Men<span class="ty-product-feature__suffix"></span>
                                </li>
                                
                                <li class="ty-product-feature__multiple-item">
                                    <span class="ty-compare-checkbox" title="Women">
                                        <i class="ty-compare-checkbox__icon ty-icon-ok"></i>
                                    </span>
                                    <span class="ty-product-feature__prefix"></span>Women<span class="ty-product-feature__suffix"></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div class="ty-product-feature">
                        <span class="ty-product-feature__label">Rim:</span>    
                        <div class="ty-product-feature__value">Full Rim</div>
                    </div>
                    
                    <div class="ty-product-feature">
                        <span class="ty-product-feature__label">Shape:</span>   
                        <div class="ty-product-feature__value">Round</div>
                    </div>
                
                    <div class="ty-product-feature">
                        <span class="ty-product-feature__label">Size
                        <span class="ty-help-info">        
                        <a id="opener_content_4_notes" class="cm-dialog-opener cm-dialog-auto-size " data-ca-target-id="content_content_4_notes" rel="nofollow">
                            <span><span class="ty-tooltip-block"><i class="ty-icon-help-circle"></i></span></span>
                        </a>

                    <div class="hidden ty-wysiwyg-content" id="content_content_4_notes" title="Size">
                        <p>Size Guide</p>
                    </div>

                    </span>:</span>
                   
                    <div class="ty-product-feature__value">
                        <ul class="ty-product-feature__multiple">
                            <li class="ty-product-feature__multiple-item">
                                <span class="ty-compare-checkbox" title="Medium (130mm~139mm)">
                                    <i class="ty-compare-checkbox__icon ty-icon-ok"></i>
                                </span>
                                <span class="ty-product-feature__prefix"></span>Medium (130mm~139mm)
                                <span class="ty-product-feature__suffix"></span>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="ty-product-feature">
                    <span class="ty-product-feature__label">Lens width:</span>     
                    <div class="ty-product-feature__value">48mm / 1.89in</div>
                </div>
                
                <div class="ty-product-feature">
                    <span class="ty-product-feature__label">Bridge width:</span>                        
                    <div class="ty-product-feature__value">21mm / 0.83in</div>
                </div>
                
                <div class="ty-product-feature">
                    <span class="ty-product-feature__label">Temple length:</span>
                    <div class="ty-product-feature__value">140mm / 5.51in</div>
                </div>
                
                <div class="ty-product-feature">
                    <span class="ty-product-feature__label">B Size:</span>
                    <div class="ty-product-feature__value">40</div>
                </div>
                
                <div class="ty-product-feature">
        <span class="ty-product-feature__label">Add Features:</span>

                            
        <div class="ty-product-feature__value"><ul class="ty-product-feature__multiple"><li class="ty-product-feature__multiple-item"><span class="ty-compare-checkbox" title="Spring Hinge"><i class="ty-compare-checkbox__icon ty-icon-ok"></i></span><span class="ty-product-feature__prefix"></span>Spring Hinge<span class="ty-product-feature__suffix"></span></li></ul></div>
        </div>
    
                                                
                            
            </div>
                                
            
                                        
            <div id="content_description" class="ty-wysiwyg-content content-description" style={{"display": "none;"}}>
                                                    
    <div>The Midas clipon unisex eyeglasses are made from high-quality acetate and feature a timeless design and round shape that is perfect for any occasion! Clipon eyeglasses are a great addition to any wardrobe for men and women. They can be used for everyday w</div>

                            
            </div>
                                
            
                                        
            <div id="content_discussion" class="ty-wysiwyg-content content-discussion" style={{"display": "none;"}}>
                                                    
    <div class="discussion-block" id="content_discussion_block">
        
        
        <div id="posts_list_607">
                            <p class="ty-no-items">No posts found</p>
                    {/* <!--posts_list_607--> */}
                    </div>

                    <div class="ty-discussion-post__buttons buttons-container">
                    
 

    <a class="ty-btn cm-dialog-opener cm-dialog-auto-size ty-btn__primary " rel="nofollow" data-ca-target-id="new_post_dialog_607">Write a review</a>

            </div>
                    
                                </div>

                            
            </div>
                                
            
            
            <div id="content_files" class="ty-wysiwyg-content content-files">
                                                    

                            
            </div>
                                
            
            
            <div id="content_buy_together" class="ty-wysiwyg-content content-buy_together">
                                                    



                            
            </div>
                                
            
            
            <div id="content_tags" class="ty-wysiwyg-content content-tags">
                                                    
    

                            
            </div>
                                
            
            
            <div id="content_attachments" class="ty-wysiwyg-content content-attachments">
                                                    

                            
            </div>
                                
            
            
            <div id="content_required_products" class="ty-wysiwyg-content content-required_products">
                                                    

                            
            </div>
            
</div>



                    
                            </div>
            <div class="ty-product-block__left ty-product-details-det product-info-block">
                                <form action="https://tnbglasses.com/" method="post" name="product_form_607" enctype="multipart/form-data" class="cm-disable-empty-files  cm-ajax cm-ajax-full-render cm-ajax-status-middle  cm-processed-form"/>
<input type="hidden" name="result_ids" value="cart_status*,wish_list*,checkout*,account_info*"/>
<input type="hidden" name="redirect_url" value="index.php?dispatch=products.view&amp;product_id=607"/>
<input type="hidden" name="product_data[607][product_id]" value="607"/>


                                                                                
                
                                


                <div class="prices-container price-wrap clearfix">
                                            <div class="ty-product-prices">
                                        <span class="cm-reload-607" id="old_price_update_607">
                        <span class="ty-list-price ty-nowrap" id="line_list_price_607"><span class="list-price-label">List price:</span> <span class="ty-strike"><span class="ty-list-price ty-nowrap">USD $</span><span id="sec_list_price_607" class="ty-list-price ty-nowrap">313.43</span></span></span>
    
        {/* <!--old_price_update_607--> */}
        </span>
                        
                                            <div class="ty-product-block__price-actual">
                                <span class="cm-reload-607 ty-price-update" id="price_update_607">
                                    <input type="hidden" name="appearance[show_price_values]" value="1"/>
                                    <input type="hidden" name="appearance[show_price]" value="1"/>
                                
                                    <span class="ty-price" id="line_discounted_price_607">
                                        <span class="ty-price-num">USD $</span>
                                        <span id="sec_discounted_price_607" class="ty-price-num">234.88</span>
                                    </span>
                                    {/* <!--price_update_607--> */}
                                </span>

                        </div>
                    
                                                    
                                        <span class="cm-reload-607" id="line_discount_update_607">
                                            <input type="hidden" name="appearance[show_price_values]" value="1"/>
                                            <input type="hidden" name="appearance[show_list_discount]" value="1"/>
                            <span class="ty-list-price ty-save-price ty-nowrap" id="line_discount_value_607"> You save: USD $<span id="sec_discount_value_607">78.55</span>&nbsp;(<span id="prc_discount_value_607">25</span>%)</span>
                    {/* <!--line_discount_update_607--> */}
                    </span>
    
                        </div>
                                    </div>
                <div class="clearfix"></div>
                                <div class="ty-product-block__option">
                                                <div class="cm-reload-607" id="product_options_update_607">
        <input type="hidden" name="appearance[show_product_options]" value="1"/>
        
                                                    
<input type="hidden" name="appearance[details_page]" value="1"/>
    <input type="hidden" name="additional_info[info_type]" value="D"/>
    <input type="hidden" name="additional_info[get_icon]" value="1"/>
    <input type="hidden" name="additional_info[get_detailed]" value="1"/>
    <input type="hidden" name="additional_info[get_additional]" value=""/>
    <input type="hidden" name="additional_info[get_options]" value="1"/>
    <input type="hidden" name="additional_info[get_discounts]" value="1"/>
    <input type="hidden" name="additional_info[get_features]" value=""/>
    <input type="hidden" name="additional_info[get_extra]" value=""/>
    <input type="hidden" name="additional_info[get_taxed_prices]" value="1"/>
    <input type="hidden" name="additional_info[get_for_one_product]" value="1"/>
    <input type="hidden" name="additional_info[detailed_params]" value="1"/>
    <input type="hidden" name="additional_info[features_display_on]" value="C"/>







<div id="option_607_AOC">
    <div class="cm-picker-product-options ty-product-options" id="opt_607">
                
                                                                                                                
        
                <div class="ty-control-group ty-product-options__item product-list-field clearfix" id="opt_607_561">
                            <label id="option_description_607_561" for="option_607_561" class="ty-control-group__label ty-product-options__item-label">Color:</label>
             
                                                        <select name="product_data[607][product_options][561]" id="option_607_561" onchange="fn_change_options('607', '607', '561');" class="hidden">
                                                                                                                                            <option value="2265" selected="selected">Black 
</option>
                                                                                                                                                <option value="2266">Purple 
</option>
                                                                        </select>
                                        
            
                        <div class="ty-product-variant-image inline-block ty-use-use-only-images-po">                                                                                                                    <span class="variant-image">    
                        <img class="ty-pict  product-variant-image-selected ty-product-options__image   cm-image" id="det_img_variant_image_607_561_2265" src="https://tnbglasses.com/images/thumbnails/20/20/variant_image/37/black.jpg" alt="" title="" onclick="fn_set_option_value('607', '561', '2265'); void(0);" />

							<span class="variant-name">Black</span>
							</span>
                        <span class="variant-image">    
                        <img class="ty-pict  product-variant-image-unselected ty-product-options__image   cm-image" id="det_img_variant_image_607_561_2266" src="https://tnbglasses.com/images/thumbnails/20/20/variant_image/37/purple.jpg" alt="" title="" onclick="fn_set_option_value('607', '561', '2266'); void(0);"/>

							<span class="variant-name">Purple</span>
							</span>
                                                                        </div>        </div>
            </div>
</div>

{/* <!-- Inline script moved to the bottom of the page -->

        
<!-- Inline script moved to the bottom of the page -->
    <!--product_options_update_607--> */}
    </div>
    
                    
                </div>
                
                <div class="ty-product-block__advanced-option clearfix">
                                                                        <div class="cm-reload-607" id="advanced_options_update_607">
                <div class="ty-control-group product-list-field">
        <label class="ty-control-group__label">Vendor:</label>
        <span class="ty-control-group__item"><a href="https://tnbglasses.com/tnb-glasses-en/">TNB Glasses</a></span>
    </div>

            
            
<input type="hidden" name="appearance[dont_show_points]" value=""/>
    <div class="ty-reward-group product-list-field hidden">
    <span class="ty-control-group__label">Reward points:</span>
    <span class="ty-control-group__item" id="reward_points_607"> points</span>
</div>

        {/* <!--advanced_options_update_607--> */}
        </div>
    
                                    </div>
                
                
                    
                        <div class="brand">
                            

    <div class="ty-features-list"><div class="ty-control-group product-list-field"><label class="ty-control-group__label">Brand: </label><span class="ty-control-group__item">    <a href="https://tnbglasses.com/eyewear-men/?features_hash=1-187">Midas</a></span></div></div>
                        </div>
                    

                


                <div class="ty-product-block__sku">
                                                    <div class="ty-control-group ty-sku-item cm-hidden-wrapper" id="sku_update_607">
            <input type="hidden" name="appearance[show_sku]" value="1"/>
            <label class="ty-control-group__label" id="sku_607">CODE:</label>
            <span class="ty-control-group__item cm-reload-607" id="product_code_607">CS8303-C1
            {/* <!--product_code_607--> */}
            </span>
        </div>
    
                </div>
                
                <div class="ty-product-block__sku">
                                                    <div class="ty-control-group ty-sku-item cm-hidden-wrapper" id="selected_color_update_607">
            <input type="hidden" name="appearance[show_selected_color]" value="1"/>
                        <label class="ty-control-group__label" id="short_selected_color_607">Color:</label>
            <span class="ty-control-group__item cm-reload-607" id="selected_color_607">Black{/*<!--selected_color_607-->*/}</span>
                    </div>
    
                </div>

                                <div class="ty-product-block__field-group">
                                        




                                            
        <div class="cm-reload-607" id="qty_update_607">
        <input type="hidden" name="appearance[show_qty]" value="1"/>
        <input type="hidden" name="appearance[capture_options_vs_qty]" value=""/>
                            
                    <div class="ty-qty clearfix changer" id="qty_607">
                <label class="ty-control-group__label" for="qty_count_607">Quantity:</label>                                <div class="ty-center ty-value-changer cm-value-changer">
                                            <a class="cm-increase ty-value-changer__increase">+</a>
                                        <input type="text" size="5" class="ty-value-changer__input cm-amount" id="qty_count_607" name="product_data[607][amount]" value="1" data-ca-min-qty="1" fdprocessedid="6fkr79"/>
                                            <a class="cm-decrease ty-value-changer__decrease">-</a>
                                    </div>
                            </div>
                            {/* <!--qty_update_607--> */}
                            </div>
    



                                            
                </div>
                
                                    

                
                                <div class="ty-product-block__button">
                                       
                                        <div class="cm-reload-607 " id="add_to_cart_update_607">
<input type="hidden" name="appearance[show_add_to_cart]" value="1"/>
<input type="hidden" name="appearance[show_list_buttons]" value="1"/>
<input type="hidden" name="appearance[but_role]" value="big"/>
<input type="hidden" name="appearance[quick_view]" value=""/>

<div class="ty-control-group">    
 

    <a href="https://tnbglasses.com/index.php?dispatch=select_lenses.view&amp;product_id=607&amp;code=CS8303-C1" class="ty-btn ty-btn__primary ty-btn__big ">Select lenses</a>
</div>
                    
 
    <button id="button_cart_607" class="ty-btn__primary ty-btn__big ty-btn__add-to-cart cm-form-dialog-closer ty-btn" type="submit" name="dispatch[checkout.add..607]" fdprocessedid="r5z8c">Add to cart</button>


    



    
<a id="opener_call_request_607" class="cm-dialog-opener cm-dialog-auto-size ty-btn ty-btn__text ty-cr-product-button" href="https://tnbglasses.com/index.php?dispatch=call_requests.request&amp;product_id=607&amp;obj_prefix=" data-ca-target-id="content_call_request_607" rel="nofollow"><span>Buy now with 1-click</span></a>

<div class="hidden" id="content_call_request_607" title="Buy now with 1-click">
    
</div>


        
 
    <a class="ty-btn ty-btn__text ty-add-to-wish cm-submit text-button " id="button_wishlist_607" data-ca-dispatch="dispatch[wishlist.add..607]">Add to wish list</a>


    
            
 
    <a class="ty-btn ty-btn__text ty-add-to-compare cm-ajax cm-ajax-full-render text-button " href="https://tnbglasses.com/index.php?dispatch=product_features.add_product&amp;product_id=607&amp;redirect_url=index.php%3Fdispatch%3Dproducts.view%26product_id%3D607" rel="nofollow" data-ca-target-id="comparison_list,account_info*">Add to comparison list</a>





{/* <!--add_to_cart_update_607--> */}
</div>


                                        
                </div>
                


                
                
    <ul class="ty-social-buttons">
                        <li class="ty-social-buttons__inline"><iframe id="twitter-widget-0" scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen="true" class="twitter-share-button twitter-share-button-rendered twitter-tweet-button" style={{"position": "static;", "visibility": "visible;", "width": "73px;", "height": "20px;"}} title="Twitter Tweet Button" src="https://platform.twitter.com/widgets/tweet_button.2b2d73daf636805223fb11d48f3e94f7.en.html#dnt=false&amp;id=twitter-widget-0&amp;lang=en&amp;original_referer=https%3A%2F%2Ftnbglasses.com%2Fsunglasses%2Fmidas-cs8303%2F&amp;size=m&amp;text=Midas-CS8303%20Eyeglasses%20%7C%20TNBGlasses.com%20%7C%20Free%20Shipping&amp;time=1694120950433&amp;type=share&amp;url=https%3A%2F%2Ftnbglasses.com%2Fsunglasses%2Fmidas-cs8303%2F" data-url="https://tnbglasses.com/sunglasses/midas-cs8303/"></iframe>
{/* <!-- Inline script moved to the bottom of the page --> */}
</li>
                                <li class="ty-social-buttons__inline"><div id="fb-root" class=" fb_reset"><div style={{"position": "absolute;", "top": "-10000px;", "width": "0px;", "height": "0px;"}}><div></div></div></div>
<div class="fb-like fb_iframe_widget" data-lang="en" data-layout="button_count" data-href="https://tnbglasses.com/sunglasses/midas-cs8303/" data-send="true" data-show-faces="false" data-action="like" data-font="arial" data-colorscheme="light" data-width="450" fb-xfbml-state="rendered" fb-iframe-plugin-query="action=like&amp;app_id=&amp;color_scheme=light&amp;container_width=0&amp;font=arial&amp;href=https%3A%2F%2Ftnbglasses.com%2Fsunglasses%2Fmidas-cs8303%2F&amp;layout=button_count&amp;locale=en_US&amp;sdk=joey&amp;send=true&amp;show_faces=false&amp;width=450"><span style={{"vertical-align": "bottom;", "width": "150px;", "height": "20px;"}}><iframe name="fbd0e24af281f8" width="450px" height="1000px" data-testid="fb:like Facebook Social Plugin" title="fb:like Facebook Social Plugin" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media" src="https://www.facebook.com/plugins/like.php?action=like&amp;app_id=&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df1d7605b6753a78%26domain%3Dtnbglasses.com%26is_canvas%3Dfalse%26origin%3Dhttps%253A%252F%252Ftnbglasses.com%252Ff35932bf29bf26c%26relation%3Dparent.parent&amp;color_scheme=light&amp;container_width=0&amp;font=arial&amp;href=https%3A%2F%2Ftnbglasses.com%2Fsunglasses%2Fmidas-cs8303%2F&amp;layout=button_count&amp;locale=en_US&amp;sdk=joey&amp;send=true&amp;show_faces=false&amp;width=450" style={{"border": "none;", "visibility": "visible;", "width": "150px;", "height": "20px;"}} class=""></iframe></span></div>
{/* <!-- Inline script moved to the bottom of the page --> */}
</li>
                                <li class="ty-social-buttons__inline"><div id="___plusone_0" style={{"position": "absolute;", "width": "450px;", "left": "-10000px;"}}><iframe ng-non-bindable="" frameborder="0" hspace="0" marginheight="0" marginwidth="0" scrolling="no" style={{"position":"absolute;", "top":"-10000px;", "width": "450px;", "margin": "0px;", "border-style":"none"}} tabindex="0" vspace="0" width="100%" id="I0_1694120949489" name="I0_1694120949489" src="https://apis.google.com/u/0/se/0/_/+1/fastbutton?usegapi=1&amp;size=standard&amp;annotation=bubble&amp;align=left&amp;expandto=top&amp;recommendations=yes&amp;origin=https%3A%2F%2Ftnbglasses.com&amp;url=https%3A%2F%2Ftnbglasses.com%2Fsunglasses%2Fmidas-cs8303%2F&amp;gsrc=3p&amp;ic=1&amp;jsh=m%3B%2F_%2Fscs%2Fabc-static%2F_%2Fjs%2Fk%3Dgapi.lb.en.IoxrLNdlTyI.O%2Fd%3D1%2Frs%3DAHpOoo9N48n3oloz8UTxoCozKcpUKaADkg%2Fm%3D__features__#_methods=onPlusOne%2C_ready%2C_close%2C_open%2C_resizeMe%2C_renderstart%2Concircled%2Cdrefresh%2Cerefresh%2Conload&amp;id=I0_1694120949489&amp;_gfid=I0_1694120949489&amp;parent=https%3A%2F%2Ftnbglasses.com&amp;pfname=&amp;rpctoken=11969029" data-gapiattached="true"></iframe></div><div class="g-plusone" data-size="standard" data-annotation="bubble" data-align="left" data-expandto="top" data-recommendations="yes" data-gapiscan="true" data-onload="true" data-gapistub="true"></div>
{/* <!-- Inline script moved to the bottom of the page --> */}
</li>
                                <li class="ty-social-buttons__inline"><a class="PIN_1694120949506_button_pin PIN_1694120949506_save" href="https://www.pinterest.com/pin/create/button/?guid=cM2mZ37sZC24-1&amp;url=https%3A%2F%2Ftnbglasses.com%2Fsunglasses%2Fmidas-cs8303%2F&amp;media=http%3A%2F%2Ftnbglasses.com%2Fimages%2Fdetailed%2F37%2FMC-CS8303-C1_2.jpg&amp;description=The%2BMidas%2Bclipon%2Bunisex%2Beyeglasses%2Bare%2Bmade%2Bfrom%2Bhigh-quality%2Bacetate%2Band%2Bfeature%2Ba%2Btimeless%2Bdesign%2Band%2Bround%2Bshape%2Bthat%2Bis%2Bperfect%2Bfor%2Bany%2Boccasion!%2BClipon%2Beyeglasses%2Bare%2Ba%2Bgreat%2Baddition%2Bto%2Bany%2Bwardrobe..." data-pin-log="button_pinit" data-pin-href="https://www.pinterest.com/pin/create/button/?guid=cM2mZ37sZC24-1&amp;url=https%3A%2F%2Ftnbglasses.com%2Fsunglasses%2Fmidas-cs8303%2F&amp;media=http%3A%2F%2Ftnbglasses.com%2Fimages%2Fdetailed%2F37%2FMC-CS8303-C1_2.jpg&amp;description=The%2BMidas%2Bclipon%2Bunisex%2Beyeglasses%2Bare%2Bmade%2Bfrom%2Bhigh-quality%2Bacetate%2Band%2Bfeature%2Ba%2Btimeless%2Bdesign%2Band%2Bround%2Bshape%2Bthat%2Bis%2Bperfect%2Bfor%2Bany%2Boccasion!%2BClipon%2Beyeglasses%2Bare%2Ba%2Bgreat%2Baddition%2Bto%2Bany%2Bwardrobe...">Save</a>
{/* <!-- Inline script moved to the bottom of the page --> */}
</li>
                </ul>


                                

                    
                            </div>
	        </div>
                    
    
    <div class="ty-discussion-post-popup hidden" id="new_post_dialog_607" title="Write a review">
<form action="https://tnbglasses.com/" method="post" class="posts-form cm-processed-form" name="add_post_form" id="add_post_form_607"/>

<input type="hidden" name="result_ids" value="posts_list*,new_post*,average_rating*"/>
<input type="hidden" name="post_data[thread_id]" value="679"/>
<input type="hidden" name="redirect_url" value="https://tnbglasses.com/sunglasses/midas-cs8303/?selected_section=discussion#discussion"/>
<input type="hidden" name="selected_section" value=""/>

<div id="new_post_607">

<div class="ty-control-group">
    <label for="dsc_name_607" class="ty-control-group__title cm-required">Your name</label>
    <input type="text" id="dsc_name_607" name="post_data[name]" value="" size="50" class="ty-input-text-large"/>
</div>

<div class="ty-control-group">
        <label for="rating_607" class="ty-control-group__title cm-required cm-multiple-radios">Your rating</label>
    <div class="clearfix cm-field-container">
    <div class="ty-rating" id="rating_607">
                        <input type="radio" id="rating_607_5" class="ty-rating__check" name="post_data[rating_value]" value="5"/><label class="ty-rating__label" for="rating_607_5" title="Excellent!">Excellent!</label>
                        <input type="radio" id="rating_607_4" class="ty-rating__check" name="post_data[rating_value]" value="4"/><label class="ty-rating__label" for="rating_607_4" title="Very Good">Very Good</label>
                        <input type="radio" id="rating_607_3" class="ty-rating__check" name="post_data[rating_value]" value="3"/><label class="ty-rating__label" for="rating_607_3" title="Average">Average</label>
                        <input type="radio" id="rating_607_2" class="ty-rating__check" name="post_data[rating_value]" value="2"/><label class="ty-rating__label" for="rating_607_2" title="Fair">Fair</label>
                        <input type="radio" id="rating_607_1" class="ty-rating__check" name="post_data[rating_value]" value="1"/><label class="ty-rating__label" for="rating_607_1" title="Poor">Poor</label>
            </div>
</div>
</div>


<div class="ty-control-group">
    <label for="dsc_message_607" class="ty-control-group__title cm-required">Your message</label>
    <textarea id="dsc_message_607" name="post_data[message]" class="ty-input-textarea ty-input-text-large" rows="5" cols="72"></textarea>
</div>



        
        <div class="captcha ty-control-group">
                    <label for="verification_answer_iv_64fa3bf5e7ecc" class="cm-required ty-captcha__label">Anti-bot validation</label>
                    <div class="cm-field-container">
                    <input class="ty-captcha__input cm-autocomplete-off" type="text" id="verification_answer_iv_64fa3bf5e7ecc" name="verification_answer" value="" autocomplete="off"/>
            <input type="hidden" name="verification_id" value="iv_64fa3bf5e7ecc"/>
                    <div class="ty-captcha__code">
                <img id="verification_image_iv_64fa3bf5e7ecc" class="ty-captcha__img" src="https://tnbglasses.com/index.php?dispatch=image.captcha&amp;verification_id=iv_64fa3bf5e7ecc&amp;no_session=Y&amp;iv_64fa3bf5e7ecc=" alt="" onclick="this.src += '|1' ;" width="110" height="30"/>
                <i class="ty-icon-refresh ty-captcha__refresh" onclick="document.getElementById('verification_image_iv_64fa3bf5e7ecc').src += '|1';"></i>
            </div>
            </div>
            {/* <!-- close .cm-field-container  --> */}
                <div>
                    {/* Type the characters you see in the picture above. */}
                    </div>
    </div>


{/* <!--new_post_607--> */}
</div>

<div class="buttons-container">
        
 
    <button class="ty-btn__secondary ty-btn" type="submit" name="dispatch[discussion.add]">Submit</button>


</div>

</div>


    </div>

    
</div>

<div class="product-details">
</div>
        </div>
        </div>
        </> 
      </div>
    );
  };

  export default GlassesCard;