import React from 'react';
import '../App.css';
import './Filter.css';
import './Navbar.css';
import { Link } from 'react-router-dom';


function Filter() {

  return (
    <div className='filter-container'>
      Filter By:

      {/**Price */}
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Price
        </a>
        <div class='dropdowns'>            
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" id="dropDownMobile">
            <div class='slidercontainer'>
              <input type="range" min="160" max="291" value="50" class="slider" id="myRange"/>
            </div>
            {/**Put Price Slider or check box */}
          </ul>
        </div>
      </li>
      
      {/**Rim */}
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Rim
        </a>
        <div class='dropdowns'>            
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" id="dropDownMobile">
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/80/16/feature_variant/2/full.png" 
                alt="" title="" 
                style={{"min-width": "80px;", "min-height": "16px; "}} />
                Full Rim
              </label>
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/64/16/feature_variant/3/half.png" 
                alt="" title="" 
                style={{"min-width": "80px;", "min-height": "16px; "}} />
                Half Rim
              </label>
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/76/16/feature_variant/3/rimless.png" 
                alt="" title="" 
                style={{"min-width": "80px;", "min-height": "16px; "}} />
                Rimless
              </label>
            </li>
          </ul>
        </div>
      </li>

      {/**Shape */}
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Shape
        </a>
        <div class='dropdowns'>            
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" id="dropDownMobile">
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                
                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/46/16/feature_variant/2/MC-8027-C1.png" 
                alt="" title="" 
                style={{"min-width": "46px;", "min-height": "16px; "}}/>
                Aviator
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                
                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/49/16/feature_variant/3/MC-8036-C1.png" 
                alt="" title="" 
                style={{"min-width": "46px;", "min-height": "16px; "}}/>
                Cat-Eye
              </label>
              </li> 
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                
                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/46/16/feature_variant/3/MC-1021-C8.png" 
                alt="" title="" 
                style={{"min-width": "46px;", "min-height": "16px; "}}/>
                Classic Wayfarers
              </label>
              </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                
                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/43/16/feature_variant/6/MC-1044-C5.png" 
                alt="" title="" 
                style={{"min-width": "46px;", "min-height": "16px; "}}/>
                Deep Oval
              </label>
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                Irregular
              </label>
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                
                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/54/16/feature_variant/3/MC-2045-C1.png" 
                alt="" title="" 
                style={{"min-width": "46px;", "min-height": "16px; "}}/>
                Oval
              </label>
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/45/16/feature_variant/6/MC-5006-C4.png" 
                alt="" title="" 
                style={{"min-width": "46px;", "min-height": "16px; "}}/>
                Oversized
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                
                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/47/16/feature_variant/3/YC-1011-C4.png" 
                alt="" title="" 
                style={{"min-width": "46px;", "min-height": "16px; "}}/>  
                Rectangle
              </label>
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/46/16/feature_variant/3/MC-2040-C1.png" 
                alt="" title="" 
                style={{"min-width": "46px;", "min-height": "16px; "}}/>  
                Round
              </label>
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/48/16/feature_variant/6/MC-1065-C4.png" 
                alt="" title="" 
                style={{"min-width": "46px;", "min-height": "16px; "}}/>           
                Square
              </label>
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/52/16/feature_variant/6/YC-2025-C3.png" 
                alt="" title="" 
                style={{"min-width": "46px;", "min-height": "16px; "}}/>
                Top-heavy
              </label>
            </li>
          </ul>
        </div>
      </li>

      {/**Size */}
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Size
        </a>
        <div class='dropdowns'>            
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" id="dropDownMobile">
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                Small (125 mm~129mm)
              </label>
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                Medium (130mm~139mm)
              </label>  
            </li>             
            <li>
              <label>
              <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                Large (140mm~145mm)
              </label>
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                X-Large ({'>'}145mm)
              </label>
            </li>            
          </ul>
        </div>
      </li>

      {/**Gender */}
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Gender
        </a>
        <div class='dropdowns'>            
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" id="dropDownMobile">
            <li>
              <label>
                  <input class="cm-product-filters-checkbox" 
                  type="checkbox" name="product_filters[7]" 
                  data-ca-filter-id="7" value="53" 
                  id="elm_checkbox_49_7_53"/>
                  Men
              </label>  
            </li>
            <li>
              <label>
                  <input class="cm-product-filters-checkbox" 
                  type="checkbox" name="product_filters[7]" 
                  data-ca-filter-id="7" value="53" 
                  id="elm_checkbox_49_7_53"/>
                  Women
              </label>  
            </li>
            <li>
              <label>
                  <input class="cm-product-filters-checkbox" 
                  type="checkbox" name="product_filters[7]" 
                  data-ca-filter-id="7" value="53" 
                  id="elm_checkbox_49_7_53"/>
                  Unisex
              </label>  
            </li>
          </ul>
        </div>
      </li>

      {/**Colors */}
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Colors
        </a>
        <div class='dropdowns'>            
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" id="dropDownMobile">
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/black.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Black
            </label>  
          </li>        
          <li>
          <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
          <img class="ty-pict cm-image" 
            src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/blue.jpg" 
            alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
            Blue
            </label>
          </li>
          <li>
          <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
          <img class="ty-pict cm-image" 
            src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/blue-parrot.jpg" 
            alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
            Blue Parrot
            </label>
          </li>
          <li>
          <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
          <img class="ty-pict cm-image" 
            src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/brown.jpg" 
            alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
            Brown
            </label>
          </li>
          <li>
          <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
          <img class="ty-pict cm-image" 
            src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/brown-transparent.jpg" 
            alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
            Brown Transparent
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/burgundy.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Burgundy
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/clear.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Clear
            </label>
          </li>
          <li>
          <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
          <img class="ty-pict cm-image" 
            src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/gold.jpg" 
            alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
            Gold
            </label>
          </li>
          <li>
          <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
          <img class="ty-pict cm-image" 
            src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/gray.jpg" 
            alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
            Gray
          </label>
          </li>
          <li>
          <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
          <img class="ty-pict cm-image" 
            src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/gray-leopard.jpg" 
            alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
            Gray Leopard
            </label>
          </li>
          <li>
          <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
          <img class="ty-pict cm-image" 
            src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/leopard.jpg" 
            alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
            Leopard
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/black.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Matte Black
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/metal.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Metal
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/panther-burgundy.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Panther
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/pink.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Pink
          </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/purple.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Purple
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/purple-transparent.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Purple Transparent
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/red.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Red
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/red-transparent.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Red Transparent
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/shiny-black.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Shiny Black
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/silver.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Silver
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/transparent.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Transparent
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/transparent-gray.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              Transparent Gray
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              
              <img class="ty-pict cm-image" 
              src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/white.jpg" 
              alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
              White
            </label>
          </li>
          <li>
          <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
              
          <img class="ty-pict cm-image" 
            src="https://tnbglasses.com/images/thumbnails/16/16/feature_variant/3/black-white.jpg" 
            alt="" title="" style={{"min-width": "16px;", "min-height": "16px; "}}/>
            White Temple
            </label>
          </li>
          </ul>
        </div>
      </li>

      {/**Material */}
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Material
        </a>
        <div class='dropdowns'>            
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" id="dropDownMobile">
          <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                Acetate
              </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              Mixed Material
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              Plastic
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              Metal
            </label>
          </li>
          <li>
            <label>
              <input class="cm-product-filters-checkbox" 
              type="checkbox" name="product_filters[7]" 
              data-ca-filter-id="7" value="53" 
              id="elm_checkbox_49_7_53"/>
              Titanium
            </label>
          </li>
          </ul>
        </div>
      </li>

      {/*Lens Width*/}
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Lens Width
        </a>
        <div class='dropdowns'>            
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" id="dropDownMobile">
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                48mm / 1.89in
              </label> 
            </li>
            <li>
              <label>
                  <input class="cm-product-filters-checkbox" 
                  type="checkbox" name="product_filters[7]" 
                  data-ca-filter-id="7" value="53" 
                  id="elm_checkbox_49_7_53"/>
                  49mm / 1.93in
                </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                50mm / 1.97in
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                51mm / 2.01in
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                52mm / 2.05in
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                53mm / 2.09in
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                54mm / 2.13in
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                55mm / 2.17in
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                56mm / 2.20in
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                60mm / 2.36in
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                62mm / 2.44in
              </label>  
            </li>
          </ul>
        </div>
      </li>

      {/**Bridge */}
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Bridge
        </a>
        <div class='dropdowns'>            
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" id="dropDownMobile">
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                15mm / 0.59in
              </label>
             </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                16mm / 0.63in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                17mm / 0.67in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                18mm / 0.71in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                19mm / 0.75in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                20mm / 0.79in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                21mm / 0.83in
              </label>
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                22mm / 0.87in
              </label>
            </li>
          </ul>
        </div>
      </li>

      {/**Temple */}
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Temple
        </a>
        <div class='dropdowns'>            
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" id="dropDownMobile">
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                135mm / 5.12in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                135mm / 5.31in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                136mm / 5.35in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                137mm / 5.39in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                138mm / 5.43in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                139mm / 5.47in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                140mm / 5.51in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                141mm / 5.55in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                142mm / 5.59in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                143mm / 5.63in
              </label>
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                145mm / 5.71in
              </label>
            </li>
          </ul>
        </div>
      </li>

      {/**B Size */}
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        B Size
        </a>
        <div class='dropdowns'>            
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" id="dropDownMobile">
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                30
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                31
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                32
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                33
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                34
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                35
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                36
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                37
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                38
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                39
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                40
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                41
              </label>  
            </li>
            <li>              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                42
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                43
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                44
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                45
              </label>  
            </li>
            <li> 
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                46
              </label>
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                49
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                55
              </label>  
            </li>
          </ul>
        </div>
      </li>

      {/**Face Shape */}
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Face Shape
        </a>
        <div class='dropdowns'>            
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" id="dropDownMobile">
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>

                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/17/16/feature_variant/4/Round2.png" 
                alt="" title="" style={{"min-width": "14px;", "min-height": "16px; "}}/>
                Round
              </label>  
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>

                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/9/16/feature_variant/4/Oval2.PNG" 
                alt="" title="" style={{"min-width": "14px;", "min-height": "16px; "}}/>
                Oval
              </label>  
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>

                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/14/16/feature_variant/4/Heart-Shaped2.PNG" 
                alt="" title="" style={{"min-width": "14px;", "min-height": "16px; "}}/>
                Heart-Shaped
              </label>  
            </li>
            <li>
            <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>

                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/10/16/feature_variant/4/Rectangle3.PNG" 
                alt="" title="" style={{"min-width": "14px;", "min-height": "16px; "}}/>
                Rectangle
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>

                <img class="ty-pict cm-image" 
                src="https://tnbglasses.com/images/thumbnails/17/16/feature_variant/4/Square2.PNG" 
                alt="" title="" style={{"min-width": "14px;", "min-height": "16px; "}}/>
                Square
              </label>  
            </li>
          </ul>
        </div>
      </li>

      {/**Features */}
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Features
        </a>
        <div class='dropdowns'>            
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" id="dropDownMobile">
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                Screwless
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                Spring Hinge
              </label>  
            </li>
            <li>
              <label>
                <input class="cm-product-filters-checkbox" 
                type="checkbox" name="product_filters[7]" 
                data-ca-filter-id="7" value="53" 
                id="elm_checkbox_49_7_53"/>
                Nose Pads
              </label>  
            </li>
          </ul>
        </div>
      </li>
    </div>

  );
}

export default Filter;