import React from 'react';
import '../App.css';
import './WomenHeader.css';

function WomenHeader() {
  return (
    <div className='women-container'>
      <h1>Women</h1>
    </div>
  );
}

export default WomenHeader;