import React, { useState } from "react";
import '../../App.css';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../LoginRegister.css'

export const Login = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [captchaValue, setCaptchaValue] = useState('');
    const siteKey = 'YOUR_RECAPTCHA_SITE_KEY'; // Replace with your reCAPTCHA site key

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    // const handleCaptchaChange = (value) => {
    //     setCaptchaValue(value);
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // if (captchaValue === '') {
        //     alert('Please complete the CAPTCHA.');
        //     return;
        // }

        // Here you can add your logic to authenticate the user
        console.log('Email:', email);
        console.log('Password:', password);
        console.log('Handle submit even hit')

        try {
            const response = await axios.post('http://67.215.10.28:2243/login', {
            email,
            password,
            });
            console.log(response.data);
            const {token} = response.data;
            localStorage.setItem('token', token)
            // localStorage.setItem('email', response.data.email)
            localStorage.setItem('email', email)
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="auth-form-container d-flex justify-content-center align-items-center container-sm containerSize">
            {/* <p><button className="link-home-btn" ><Link to="/">Home</Link></button>/Login</p> */}
            
            <div class='container-sm form-container'>
                <header class='text-center'>Login</header>
                <form className="login-form" onSubmit={handleSubmit}>
                    {/* <label htmlFor="email">Email:*</label>
                    <input type="email" value={email} onChange={handleEmailChange} placeholder="youremail@gmail.com" id="email" name="email" />
                    
                    <label htmlFor="password">Password:*</label>
                    <input type="password" value={password} onChange={handlePasswordChange} placeholder="********" id="password" name="password" /> */}
                    

                    <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="floatingInput email" placeholder="name@example.com" name="email" value={email} onChange={handleEmailChange}/>
                        <label for="floatingInput">Email address</label>
                    </div>
                    <div class="form-floating">
                        <input type="password" class="form-control" id="floatingPassword password" name="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                        <label for="floatingPassword">Password</label>
                    </div>


                    <label htmlFor="botValid" class="d-block">Validate: *</label>
                    {/* <input value={password} onChange={(e) => setPassword(e.target.value)} type="botValid" placeholder="********" id="botValid" name="botValid" />
                    <p className='text'>Type the characters you see in the picture above. </p> */}
                    {/*<ReCAPTCHA sitekey={siteKey} onChange={handleCaptchaChange} />*/}
                    
                    {/* <button  type="submit">Login</button>  */}
                     <div class="container">
                        <div class="row d-flex justify-content-evenly">
                            {/* <div class="col">
                                <Link to="/"><Button>Home</Button></Link>  
                            </div>
                            <div class="col">
                                <Button type="submit">Login</Button>
                            </div>           */}
                            <div class='row'>
                                {/* <div class="col-md-6 bg-dark">
                                    <Link to="/"><Button class='btn btn-primary btn-block'>Home</Button></Link>  
                                </div>
                                <div class="col-md-6 fill-height bg-info">
                                    <Button type="submit" class='btn btn-primary btn-block   w-100 h-100 flex-fill'>Login</Button>
                                </div> */}
                                <div className='btn-group ' role='group' id='loginBtnContainer'>
                                    <Button type="submit button" className='my-custom-btn'  class='buttonColor' id="loginBtn">LOGIN</Button>

                                </div>
                                
                                <div className='btn-group spacer' role='group'>
                                    <NavLink to="/register" className="btn nav-link    my-custom-btn" role="button" id="registerBtn">REGISTER</NavLink>
                                </div>
                                
                            </div>
                        </div>
                    </div> 

                {/* <img src={ require('./midas_1.png')} alt="logo" className='img'/> */}

                {/* <img src={ require('./midas_logo.png')} alt="" className="logo"/> */}
                </form>
                {/* <Link to="/register"><Button>Don't have an account? Register here.</Button></Link>  */}
                {/* <button className="link-btn" ><Link to="/register">Don't have an account? Register here.</Link></button> */}
            </div>
        </div>
    )
}

export default Login;