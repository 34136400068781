import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFacebookF , faInstagram} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope} from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <footer class='container-fluid bg-dark text-white'>
      <div className='container p-2'>
        <section>
          <div class="d-flex justify-content-center">
              <div class='footer-link-items'>
                <h2>About Us</h2>
                <Link to='/aboutUs'>Our Company</Link>
                <Link to='/policies'>Policies</Link> {/*make page for benefits*/}
              </div>
              <div class='footer-link-items'>
                <h2>Contact Us</h2>
                <a class="text-white">
                  <FontAwesomeIcon icon={faPhone}/> Call Us: 1-844-387-4289</a>
                <a class="text-white">
                  <FontAwesomeIcon icon={faEnvelope}/> Email Us: info@tnbglasses.com</a>
                <Link to='/map'>Destinations</Link>
              </div>
              <div class='footer-link-items'>
                <h2>Social Links</h2>
                  <section class='mb-2'>
                    <div class="d-flex justify-content-center">
                      <a class="btn btn-outline-light m-2" href="https://www.facebook.com/tnbglasses/" role="button">
                        <FontAwesomeIcon icon={faFacebookF} size="1.5x"/>
                      </a>
                      <a class="btn btn-outline-light m-2" href="https://www.instagram.com/midas_eyewear/" role="button">
                        <FontAwesomeIcon icon={faInstagram} size="1.5x" />
                      </a>
                    </div>
                  </section>
              </div>
            </div>
        </section>

        <section>
          <small class='website-rights'>Midas © 2020</small>
        </section>
      </div>
    </footer>
  );
}

export default Footer;