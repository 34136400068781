import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import cart from '../components/images/shopping-bag.png'
import axios from 'axios';
// import admin from '../../server'
function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(true);                                                //NEED to set back to false just leaving true for testing re.
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  const userType = 'admin'

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };
  // const handleLogin = () => {
  //   setIsLoggedIn(true);
  // };

  // const handleLogout = () => {
  //   setIsLoggedIn(false);
  // };

  useEffect(() => {
    showButton();
    loginCheck();
  }, []);

  window.addEventListener('resize', showButton);

  const testStyle = {
    backgroundColor: 'red'
  }

  const loginCheck = async () => {
    try{
      const res = await axios.post('/login')
      const {admin} = res.data
      setIsLoggedIn(admin.isLoggedIn)
    }catch(error){
      console.log('login checker error', error)
    }
  }

  return (
    <>
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark customnav">
      <Link to='/' className='navbar-logo' onClick={closeMobileMenu} id='titles'>
      <img src={ require('./images/midas_logo.png')} alt="logo" className='img'/>
      {/* MIDAS */}
      </Link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation" id="navbarTogglerBtn">
          <span class="navbar-toggler-icon" id="testSpan"></span>
        </button>
        <div class="collapse navbar-collapse ml-auto" id="navbarTogglerDemo01">
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li className='nav-item'>
                <Link to='/collection' className='nav-links' onClick={closeMobileMenu}> Collection</Link>
            </li>
            <li className='nav-item'>
              <Link to='/Eyeglasses' className='nav-links' onClick={closeMobileMenu}>Eyeglasses</Link>
            </li>

            <li className='nav-item'>
              <Link to='/sunglasses' className='nav-links' onClick={closeMobileMenu}>Sunglasses</Link>
            </li>
            
            <li className='nav-item'>
              <Link to='/map' className='nav-links' onClick={closeMobileMenu}>Map</Link>
            </li>

            {isLoggedIn &&
            <li className='nav-item'>
              <Link to='/admin' className='nav-links' onClick={closeMobileMenu}>Admin</Link>
            </li>}

              <li className='nav-item'>
                <Link to='/cart' className='nav-links' onClick={closeMobileMenu}><img class='shop' src={cart} alt=""/></Link>
              </li>


              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Account
                </a>
                <div class='dropdowns'>
             
                  <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" id="dropDownMobile">
                    <li><Link to='/profile' class="dropdown-item">Profile</Link></li>
                    <li><Link to='/orders' class="dropdown-item">Orders</Link></li>
                    <li><Link to='/settings' class='dropdown-item'>Settings</Link></li>
                    <li><hr></hr></li>
                    <li><Link to='/login' class='dropdown-item'>Login</Link></li>
                    
                    {/* <li>{button && <Button buttonStyle='btn--outline' class='dropdown-item' id='loginFix'>Login</Button>}</li> *buttonStyle='btn--outline' */}
                  </ul>
                </div>
              </li>
          </ul>
        </div>
      </nav>
 
      


      
          </>
        );
}
            
export default Navbar;