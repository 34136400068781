import React from 'react';
import './Settings.css';

function Settings() {
  document.addEventListener('DOMContentLoaded', function() {
    const settingsForm = document.getElementById('settings-form');

    settingsForm.addEventListener('submit', function(event) {
        event.preventDefault();

        const name = document.getElementById('name').value;
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;

        // You can add logic here to save the settings to the server
        // For now, we'll just display an alert with the updated information
        alert(`Settings Updated:\nName: ${name}\nEmail: ${email}\nPassword: ${password}`);
    });
  });

  return (
    <div class="settings-container">
        <h1>Account Settings</h1>
        <form id="settings-form">
            <div class="form-group">
                <label for="name">Name:</label>
                <input type="text" id="name" placeholder="Your Name"/>
            </div>
            <div class="form-group">
                <label for="email">Email:</label>
                <input type="email" id="email" placeholder="you@example.com"/>
            </div>
            <div class="form-group">
                <label for="password">Password:</label>
                <input type="password" id="password" placeholder="Password"/>
            </div>
            {/* <div class="form-group">
                <label for="password">Password:</label>
                <input type="password" id="password" placeholder="Password"/>
            </div> */}
            <button type="submit">Save Changes</button>
        </form>
    </div>
  );
};

export default Settings;