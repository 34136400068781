import React from 'react';
import '../../App.css';
import WomenHeader from '../WomenHeader';
import Filter from '../Filter';
import Footer from '../Footer';

function Women() {
    return (
      <div>
        <>
        <WomenHeader />
        <Filter />
        </> 
      </div>
    );
  };

  export default Women;