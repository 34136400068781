import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import 'bootstrap/dist/css/bootstrap.min.css';
import {PayPalScriptProvider, PayPalButtons} from '@paypal/react-paypal-js'
import Footer from '../Footer'
function Cart() {


  // const PayPalPaymentForm = () => {
  //   const clientId = 'YOUR_CLIENT_ID'; //clientId replaced with paypal sandbox client id (dev acc)
  
  //   Payment processed successfully
  //   const onSuccess = (details, data) => {
  //     alert('Transaction completed by ' + details.payer.name.given_name);
  //   };


  return (
    <div>
      <>
      <div className="App container d-flex justify-content-center align-items-center">
      <header className="App-header">
        <div class='container entire-container-items'>
          <div class = 'row align-items-start'>
            <h4 class='heading'>Name/Business Name Order</h4> {/*Add company or individual here*/}
            <div class='col'>
              <h5>Items being ordered</h5>
              <hr></hr>
                <div class="container-fd  p-3 table-responsive">
                  <Table class='table table-bordered table-striped table-hover' id='order_table'>
                    <thead>
                      <tr>
                        <th scope='col' id='sku'>Sku</th>
                        <th scope='col' id='gender'>Gender</th>
                        <th scope='col' id='color'>Color</th>
                        <th scope='col' id='price'>Price</th>
                      </tr>
                    </thead>
                    <tbody id='order_table_body'>
                      <tr>
                        <td class='overflow-auto'>5003M-10</td>
                        <td class='overflow-auto'>Grey Leopard & Pink</td>
                        <td class='overflow-auto'>Women</td>
                        <td class='overflow-auto'>187.15</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <hr></hr>
                <div class="d-flex justify-content-between">
                  <p>Total Cost:</p>
                  <p class="text-end">$194.18</p>
                </div>
            </div>
            <div class='col'>


              <PayPalScriptProvider> {/**options={{ 'client-id': clientId}} */}
              <div>
                <form>
                  <input type="hidden" id="amount" name="amount" value="1.00" /> {/* Replace with your desired amount */}
                </form>

                <PayPalButtons
                  createOrder={(data, actions) => {
                    return actions.order.create({
                      purchase_units: [{
                        amount: {
                          value: document.getElementById('amount').value // Get the amount from the form field
                        }
                      }]
                    });
                  }}
                  // onApprove={(data, actions) => {
                  //   return actions.order.capture().then((details) => onSuccess(details, data));
                  // }}
                />
              </div>
            </PayPalScriptProvider>  
 
            </div>
          </div>
        </div>
         
      </header>
    </div>
      </>
    </div>
  );
}

export default Cart;