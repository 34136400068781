import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Filter from '../Filter';
import SunglassesHeader from '../SunglassesHeader';

export default function Products() {
  return (
    <div>
      <>
      <SunglassesHeader />
      <Filter />
      </> 
    </div>
  );
}