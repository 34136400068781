import React, { useState } from "react";
import '../../App.css';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../LoginRegister.css'

export const Register = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [captchaValue, setCaptchaValue] = useState('');
    const siteKey = 'YOUR_RECAPTCHA_SITE_KEY'; // Replace with your reCAPTCHA site key


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    
      const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };
    
    const handleSubmitReg = async (e) => { {/**Used to be named handleSubmit */}
        e.preventDefault();
        // if (captchaValue === '') {
        //   alert('Please complete the CAPTCHA.');
        //   return;
        // }
          // Here you can add your logic to register the user
          console.log('Email:', email);
          console.log('Password:', password);
  
          try {
              const response = await axios.post('/register', {
                email,
                password,
                name,
              });
        
              console.log(response.data);
              // Handle success message or redirect to a different page
            } catch (error) {
              console.error('Error occurred during registration:', error);
              // Handle error message or display an error to the user
            }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (captchaValue === '') {
          alert('Please complete the CAPTCHA.');
          return;
        }
        // Here you can add your logic to register the user
        console.log('Email:', email);
        console.log('Password:', password);
    };

    
    return (
      <> 
        <div className="auth-form-container d-flex justify-content-center align-items-center container-sm containerSize">
          <div class='container-sm form-container'>
            <header class='text-center'>Register</header>
            <form className="register-form" onSubmit={handleSubmitReg}> {/**handleSubmit used to be named */}
              <div class='form-floating mb-3'>
                <input class='form-control' value={name} name="name" onChange={(e) => setName(e.target.value)} id="floatingName name" placeholder="Full Name" />
                <label for="floatingName">Full Name</label>
              </div>
            
              <div class='form-floating mb-3'>
               <input class='form-control' type="email" value={email} onChange={handleEmailChange} placeholder="youremail@gmail.com" id="floatingEmail email" name="email" />
               <label for="floatingEmail">Email</label>
              </div>
                
              <div class='form-floating mb-3'>
               <input class='form-control' type="password" value={password} onChange={handlePasswordChange} placeholder="********" id="password" name="password" />
               <label htmlFor="password">Password</label>
              </div>

              
              <div class="container">
                <div class="row d-flex justify-content-evenly">
                  <div class='row'>
                    <Button type="submit" class="btn btn-primary btn-block" id="registerBtnReg">Register</Button>
                  </div>
                </div>
              </div>

              <div class='container'>
                <div class='row d-flex justify-content-evently'>
                  <div class='row'>
                    <label htmlFor="botValid">Anti-bot Validation</label>
                    <ReCAPTCHA sitekey={siteKey} onChange={handleCaptchaChange} /> {/*Needs to have script key, that's why it doesn't work*/}
                  </div>
                </div>
              </div>

               {/* <input value={password} onChange={(e) => setPassword(e.target.value)} placeholder="********" id="botValid" name="botValid" className='captcha'/>
              <button className="wrapper" id="reload-btn">
                  <i class="fa-solid fa-arrow-rotate-right"></i>
              </button>
              <p className='text'>Type the characters you see in the picture above. </p> */}
 
              {/* <button type="submit">Login</button> */}
  

          </form>

          {/* <img src={ require('./midas_1.png')} alt="logo" className='img'/> */}

          {/* <button className="link-btn" ><Link to="/login">Already have an account? Login here.</Link></button> */}
          <div class='container'>
            <div class='row d-flex justify-content-evently'>
              <div class='row'>
                <Button id='buttonLinkEdit'><Link to="/login" class='btn btn-primary btn-block buttonColor' id='loginBtnReg'>Already have an account? Login</Link></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
