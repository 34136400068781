import React from 'react';
import '../App.css';
import './CollectionHeader.css';

function CollectionHeader() {
  return (
    <div className='collection-header'>
      <h1>Collection</h1>
    </div>
  );
}

export default CollectionHeader;