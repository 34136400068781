import React from 'react';
import './Card.css';
import { Link } from 'react-router-dom';

function Cards() {
  return (
      <div class='row-fluid'>
        <div class='span12 '>
          <div class='fixed-width'>
            <div class='home-content'>
              <div class='promo-clearfix'>
                <div class='left-column'>

                  <div class='promo-banner1'>
                    <Link to='/collection' class='promo-inner'>
                    <img src={ require('./images/Collections.jpg')} alt="logo" className='img-left'/>
                    <div class="promo-text1">
                      <h3 class="left">
                        <em>new</em> COLLECTION 2021
                      </h3>
                    </div>
                    </Link>
                  </div>

                  <div class='promo-banner1'>
                    <Link to='/bestsellers' class='promo-inner'>
                    <img src={ require('./images/women1.png')} alt="logo" className='img-left'/>
                    <div class="promo-text1">
                      <h3 class="left">
                        BESTSELLERS
                      </h3>
                    </div>
                    </Link>
                  </div>

                </div>

                <div class='right-column'>

                <div class='promo-banner2'>
                    <Link to='/men' class='promo-inner'>
                    <img src={ require('./images/men.jpg')} alt="logo" className='img-right'/>
                    <div class="promo-text2">
                      <h3 class="right">
                        <em>for</em> MEN
                      </h3>
                    </div>
                    </Link>
                  </div>

                  <div class='promo-banner2'>
                    <Link to='/women' class='promo-inner'>
                    <img src={ require('./images/woman.jpg')} alt="logo" className='img-right'/>
                    <div class="promo-text2">
                      <h3 class="right">
                      <em>for</em> WOMEN
                      </h3>
                    </div>
                    </Link>
                  </div>

                  <div class='promo-banner2'>
                    <Link to='/sunglasses' class='promo-inner'>
                    <img src={ require('./images/sunglasses1.jpg')} alt="logo" className='img-right'/>
                    <div class="promo-text2">
                      <h3 class="right">
                        SUNGLASSES
                      </h3>
                    </div>
                    </Link>
                  </div>

                  <div class='promo-banner2'>
                    <Link to='/eyeglasses' class='promo-inner'>
                    <img src={ require('./images/eyeglasses2.jpg')} alt="logo" className='img-right'/>
                    <div class="promo-text2">
                      <h3 class="right">
                        EYEGLASSES
                      </h3>
                    </div>
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Cards;