import React,{ useState, useEffect } from 'react';
// import { Helmet } from 'react-helmet'
import Axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css'
import './categories-page.css'
 
import {Table} from 'react-bootstrap'

const CategoriesPage = (props) => {

    const [categoryNumData, setCategoryNumData] = useState([])
    Axios.defaults.baseURL = 'https://midaseyewear.ca' //localhost:5000/count
    
    // const handleClick = (item) => {
    //     console.log(item)
    // }

    useEffect(()=>{
        const fetchData = async () => {
          try{
            const response = await Axios.get('/count')
            setCategoryNumData(response.data)
            console.log('Response: ' + response.data)
          }catch(error){
            console.error("Error fetching data:", error)
          }
        }
        fetchData()
      }, [])



  const [data, setData] = useState([
    {pos: 0, name: 'Men', products: 0, status: 'Active'},
    {pos: 10, name: 'M_Eyeglasses', products: 0, status: 'Active'},
    {pos: 20, name: 'M_Sunglasses', products:0 , status: 'Active'},
    {pos: 30, name: 'M_Bestsellers', products: 0, status: 'Active'},
    {pos: 40, name: 'M_Sale', products: 0, status: 'Active'},

    {pos: 50, name: 'Women', products: 0, status: 'Active'},
    {pos: 60, name: 'W_Eyeglasses', products: 0, status: 'Active'},
    {pos: 70, name: 'W_Sunglasses', products: 0, status: 'Active'},
    {pos: 80, name: 'W_Bestsellers', products: 0, status: 'Active'},
    {pos: 90, name: 'W_Sale', products: 0, status: 'Active'},

    {pos: 100, name: 'Bestseller', products: 0, status: 'Active'},
    {pos: 110, name: 'Sale', products: 0, status: 'Active'},
    {pos: 120, name: 'Brands', products: 0, status: 'Active'},
    {pos: 130, name: 'New Collection', products: 0, status: 'Active'}
  ])

  /**Utilizied for data loading. Not loading numbers like this from categiryNum gives us no numbers */
  useEffect(()=>{
    if(categoryNumData.length > 1){
        const newData = [...data]
        for(let i = 0; i < categoryNumData[0].length; i++){
            newData[i].products = categoryNumData[0][i]
        }
        setData(newData)
    }
  }, [categoryNumData])

  return (
    <div className="container-fluid categories-page-container">
        <div class="container-wrapper">
          <div class="container2 scrollable-container">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>pos</th>
                  <th>name</th>
                  <th>products</th>
                  <th>status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item)=>(
                  <tr key={item.pos} onClick={() =>  console.log("Hello world")}>
                    <td>{item.pos}</td>
                    <td>{item.name}</td>
                    <td>{item.products}</td>
                    <td>{item.status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div class="container3">
            <h1>Category Information</h1>
            <div class="container4">
              <hr></hr>
              <h5>Categories<p>{categoryNumData[2]}</p></h5>
              <h5>Products<p>{categoryNumData[1]}</p></h5>
              <h5>Active Categories<p>{categoryNumData[3]}</p></h5>
              <h5>Hidden Categories<p>{categoryNumData[4]}</p></h5>
              <h5>Disabled Categories<p>{categoryNumData[4]}</p></h5>
            </div>
          </div>
        </div>
    </div>
  )
}

export default CategoriesPage
