import React from 'react';
import '../App.css';
import './EyeglassesHeader.css';

function EyeglassesHeader() {
  return (
    <div className='eyeglasses-header'>
      <h1>Eyeglasses</h1>
    </div>
  );
}

export default EyeglassesHeader;