import React from 'react';
import '../../App.css';
import Filter from '../Filter';
import Footer from '../Footer';

function Orders() {
    return (
      <div>
        <>
        <div class='orders'>

        </div>
        </> 
      </div>
    );
  };

  export default Orders;