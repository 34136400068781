import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Filter from '../Filter';
import CollectionHeader from '../CollectionHeader';

function Collection() {
  return (
    <div>
      <>
      <CollectionHeader />
      <Filter />
      </> 
    </div>
  );
};

export default Collection;