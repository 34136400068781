import React from 'react';
import '../../App.css';
import MenHeader from '../MenHeader';
import Filter from '../Filter';
import Footer from '../Footer';
import SearchBar from '../SearchBar';
import { Link } from 'react-router-dom';

function Men() {
    return (
      <div>
        <>
        <MenHeader />
        <SearchBar />
        <Filter />
        <Link to='/glassesCard'>Glasses Card Info</Link>
        </> 
      </div>
    );
  };

  export default Men;