import React from 'react';
import '../../App.css';
import Footer from '../Footer';

function Bestsellers() {
  return (
    <div>
      <>
      <div class='bestsellers'>

      </div>
      </> 
    </div>
  );
};

export default Bestsellers;