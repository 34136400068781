import React, { useEffect } from 'react';

export const Map = (props) => {
  const locations = [
    {
      name: 'Wizard Of Eyes',
      address: '1126 Eglinton West',
      coords: {
        lat: 43.6999,
        lng: -79.4322
      },
      content: '<h1>Wizard Of Eyes: 1126 Eglinton West</h1>'
    },
    {
      name: 'Temkin Opticians',
      address: '8707 Dufferin St #1',
      coords: {
        lat: 43.8293, 
        lng: -79.4785
      },
      content: '<h1>Temkin Opticians: 8707 Dufferin St #1</h1>'
    },
    {
      name: 'Universal Optical',
      address: '3128 Bathurst St.',
      coords: {
        lat: 43.7205, 
        lng: -79.4304
      },
      content: '<h1>Universal Optical: 3128 Bathurst St.</h1>'
    },
    {
      name: 'Pro-Optical Ltd',
      address: '660 Eglinton Ave. E.',
      coords: {
        lat: 43.7116, 
        lng: -79.3767
      },
      content: '<h1>Pro-Optical Ltd: 660 Eglinton Ave. E.</h1>'
    },
    {
      name: 'Eye Fashion Optical',
      address: '4400 Dufferin Street',
      coords: {
        lat: 43.7646, 
        lng: -79.4680
      },
      content: '<h1>Eye Fashion Optical: 4400 Dufferin Street</h1>'
    },
    {
      name: 'Optic Jewel',
      address: 'Cedarbrae Mall',
      coords: {
        lat: 43.7580, 
        lng: -79.2288
      },
      content: '<h1>Optic Jewel: Cedarbrae Mall</h1>'
    },
    {
      name: 'Dr. Olga Giraldo',
      address: '3232 Steeles Ave W, Unit 11',
      coords: {
        lat: 43.7750, 
        lng: -79.5261
      },
      content: '<h1>Dr. Olga Giraldo: 3232 Steeles Ave W, Unit 11</h1>'
    },
    {
      name: 'Optical Elegance',
      address: '800 Steeles Ave W',
      coords: {
        lat: 43.7934,
        lng: -79.4470
      },
      content: '<h1>Optical Elegance: 800 Steeles Ave W</h1>'
    },
    {
      name: 'Idol Eyes N Feet',
      address: '9333 Weston Road',
      coords: {
        lat: 43.8275, 
        lng: -79.5560
      },
      content: '<h1>Idol Eyes N Feet: 9333 Weston Road</h1>'
    },
    {
      name: 'i Optika',
      address: '2943 Major Mackenzie Dr Unit 5',
      coords: {
        lat: 43.8487, 
        lng: -79.5347
      },
      content: '<h1>i Optika: 2943 Major Mackenzie Dr Unit 5</h1>'
    },
    {
      name: 'Feinstein Eyecare',
      address: '9184 Yonge St',
      coords: {
        lat: 43.8508, 
        lng: -79.4336
      },
      content: '<h1>Feinstein Eyecare: 9184 Yonge St</h1>'
    },
    {
      name: 'Leo Optical',
      address: '1869 Finch Ave West',
      coords: {
        lat: 43.7571,
        lng: -79.5152
      },
      content: '<h1>Leo Optical: 1869 Finch Ave West</h1>'
    },
    {
      name: 'Ashleys Optical Outlet',
      address: '2359 Finch Ave West',
      coords: {
        lat: 43.7513,
        lng: -79.5428
      },
      content: '<h1>Ashleys Optical Outlet: 2359 Finch Ave West</h1>'
    },
    {
      name: 'Oculus Eyecare',
      address: '3600 Langstaff Road Unit 5',
      coords: {
        lat: 43.8093, 
        lng: -79.5474
      },
      content: '<h1>Oculus Eyecare: 3600 Langstaff Road Unit 5</h1>'
    },
    {
      name: 'Fresh Eyes Optical',
      address: '1995A Finch Ave West',
      coords: {
        lat: 43.7564, 
        lng: -79.5191
      },
      content: '<h1>Fresh Eyes Optical: 1995A Finch Ave West</h1>'
    },
    {
      name: 'i-LifeStyle',
      address: 'East York Town Centre; 45 Overlea Blvd',
      coords: {
        lat: 43.7057,
        lng: -79.3458
      },
      content: '<h1>i-LifeStyle: East York Town Centre; 45 Overlea Blvd</h1>'
    },
    {
      name: 'InStyle Optical',
      address: '828 College St.',
      coords: {
        lat: 43.6546, 
        lng: -79.4224
      },
      content: '<h1>InStyle Optical: 828 College St.</h1>'
    },
    {
      name: 'Optical Palace',
      address: '1530 Albion Rd',
      coords: {
        lat: 43.7417,
        lng: -79.5845
      },
      content: '<h1>Optical Palace: 1530 Albion Rd</h1>'
    },
    {
      name: 'Dala Optical',
      address: '88 Main St E',
      coords: {
        lat: 43.5102,
        lng: -79.8860
      },
      content: '<h1>Dala Optical: 88 Main St E</h1>'
    },
    {
      name: 'Your Eye Dr',
      address: '1380 Major Mackenzie Dr',
      coords: {
        lat: 43.8621, 
        lng: -79.4846
      },
      content: '<h1>Your Eye Dr: 1380 Major Mackenzie Dr</h1>'
    },
    {
      name: 'Optical Eyeworks',
      address: '642 Major Mckenzie Dr. E',
      coords: {
        lat: 43.8764, 
        lng: -79.4173
      },
      content: '<h1>Optical Eyeworks: 642 Major Mckenzie Dr. E</h1>'
    },
    {
      name: 'Eurostyle Optical',
      address: '6462 Yonge St',
      coords: {
        lat: 43.7961, 
        lng: -79.4215
      },
      content: '<h1>Eurostyle Optical: 6462 Yonge St</h1>'
    },
    {
      name: 'Eye Care Optical',
      address: '390 Steeles Ave W Unit 16',
      coords: {
        lat: 43.7960, 
        lng: -79.4340
      },
      content: '<h1>Eye Care Optical: 390 Steeles Ave W Unit 16</h1>'
    },
    {
      name: 'URBAN OPTICIANS',
      address: '9200 Bathurst St. Unit #20',
      coords: {
        lat: 43.8468, 
        lng: -79.4591
      },
      content: '<h1>URBAN OPTICIANS: 9200 Bathurst St. Unit #20</h1>'
    },
    {
      name: 'Woodside Eyecare',
      address: '1571 Sandhurst Circle Unit 175',
      coords: {
        lat: 43.8084, 
        lng: -79.2697
      },
      content: '<h1>Woodside Eyecare: 1571 Sandhurst Circle Unit 175</h1>'
    },
    {
      name: 'Famous Optical',
      address: 'Pickering Town Centre;  1355 Kingston Rd',
      coords: {
        lat: 43.8347, 
        lng: -79.0852
      },
      content: '<h1>Famous Optical: Pickering Town Centre;  1355 Kingston Rd</h1>'
    },
    {
      name: 'Sheridan Optical Centre',
      address: 'Sheridan Mall Box 21, 1700 Wilson Ave',
      coords: {
        lat: 43.7213, 
        lng: -79.5115
      },
      content: '<h1>Sheridan Optical Centre: Sheridan Mall Box 21, 1700 Wilson Ave</h1>'
    },
    {
      name: 'Famous Optical',
      address: '2150 Burnhamthorpe Rd W unit H15',
      coords: {
        lat: 43.5451,
        lng: -79.6823
      },
      content: '<h1>Famous Optical: 2150 Burnhamthorpe Rd W unit H15</h1>'
    },
    {
      name: 'Clarity Optical',
      address: '700 Lawrence Ave W, Unit #130',
      coords: {
        lat: 43.7163, 
        lng: -79.4463
      },
      content: '<h1>Clarity Optical: 700 Lawrence Ave W, Unit #130</h1>'
    },
    {
      name: 'Aziz Optical',
      address: '499 Main Street South Unit#187',
      coords: {
        lat: 43.6660,
        lng: -79.7376
      },
      content: '<h1>Aziz Optical: 499 Main Street South Unit#187</h1>'
    },
    {
      name: 'Optical Valu Inc',
      address: 'Yorkgate Mall, 1 York Gate Blvd',
      coords: {
        lat: 43.7586, 
        lng: -79.5197
      },
      content: '<h1>Optical Valu Inc: Yorkgate Mall, 1 York Gate Blvd</h1>'
    },
    {
      name: 'La Look Optic',
      address: '5650 Yonge St # 8',
      coords: {
        lat: 43.7801, 
        lng: -79.4164
      },
      content: '<h1>La Look Optic: 5650 Yonge St # 8</h1>'
    },
    {
      name: 'Scarboro Optical & Contact Lens Centre',
      address: '41 Lebovic Avenue Unit A-108',
      coords: {
        lat: 43.7232, 
        lng: -79.2889
      },
      content: '<h1>Scarboro Optical & Contact Lens Centre: 41 Lebovic Avenue Unit A-108</h1>'
    },
    {
      name: 'Optix',
      address: '1 Eglinton Square',
      coords: {
        lat: 43.7237, 
        lng: -79.2992
      },
      content: '<h1>Optix: 1 Eglinton Square</h1>'
    },
    {
      name: 'Abel Optical',
      address: '1660 Kingston Rd',
      coords: {
        lat: 43.6917,
        lng: -79.2647
      },
      content: '<h1>Abel Optical: 1660 Kingston Rd</h1>'
    },
    {
      name: 'Optical Visions',
      address: '533 Roger Rd',
      coords: {
        lat: 45.3978,
        lng: -75.6503
      },
      content: '<h1>Optical Visions: 533 Roger Rd</h1>'
    },
    {
      name: 'Optical on Main Street',
      address: '165 Main St. South',
      coords: {
        lat: 44.0540, 
        lng: -79.4578
      },
      content: '<h1>Optical on Main Street: 165 Main St. South</h1>'
    },
    {
      name: 'Eyecare On Wynword',
      address: '20 Wynford Drive, Suite # 100',
      coords: {
        lat: 43.7239,
        lng: -79.3363
      },
      content: '<h1>Eyecare On Wynword: 20 Wynford Drive, Suite # 100</h1>'
    },
    {
      name: '3 For 1 Glasses',
      address: '300 King George Rd',
      coords: {
        lat: 43.1832, 
        lng: -80.2810
      },
      content: '<h1>3 For 1 Glasses: 300 King George Rd</h1>'
    },
    {
      name: 'Victoria Optical',
      address: '1182 Victoria Park Ave.',
      coords: {
        lat: 43.7093, 
        lng: -79.2964
      },
      content: '<h1>Victoria Optical: 1182 Victoria Park Ave.</h1>'
    },
    {
      name: 'I Care Optique',
      address: '217 Fleetwood Cres',
      coords: {
        lat: 43.7213, 
        lng: -79.7111
      },
      content: '<h1>I Care Optique: 217 Fleetwood Cres</h1>'
    },
    {
      name: 'Akal Optical',
      address: '380 Bovaird Dr E #27',
      coords: {
        lat: 43.7145, 
        lng: -79.7806
      },
      content: '<h1>Akal Optical: 380 Bovaird Dr E #27</h1>'
    },
    {
      name: 'Invision Optical',
      address: '511 Maple Grove Drive, Unit #25',
      coords: {
        lat: 43.4797, 
        lng: -79.6559
      },
      content: '<h1>Invision Optical: 511 Maple Grove Drive, Unit #25</h1>'
    }
  ];

  useEffect(() => {
    function initMap() {
      var options = {
        zoom: 8,
        center: {
          lat: 43.6952,
          lng: -79.3691
        }
      };

      var map = new window.google.maps.Map(document.getElementById('map'), options);

      for (var i = 0; i < locations.length; i++) {
        addMarker(locations[i]);
      }

      function addMarker(location) {
        var marker = new window.google.maps.Marker({
          position: location.coords,
          map: map,
        });

        var infoWindow = new window.google.maps.InfoWindow({
          content: location.content
        });

        marker.addListener('click', function () {
          infoWindow.open(map, marker);
        });
      }
    }

    // Load the Google Maps API
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCdLjek6SA7yUxXUefnUMTKlCN6MiWMcTo&callback=initMap`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    return () => {
      //component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <div>
        <h1>TnB Glasses Seller Locations</h1>
        <div id="map" style={{ height: '400px', width: '100%' }}></div>
      </div>
    </>
    
  );
}

export default Map;