import React from 'react';
import '../../App.css';
import Footer from '../Footer';

function Policies() {
    return (
      <div>

        <div class='mainbody'>
            <div class='privacy-content'>
            <h1><span>Privacy Policy</span></h1>
            
            <h3>What information do we collect?</h3>
            <p>We collect information from you when you register on our site, place an order, 
                subscribe to our newsletter or fill out a form.</p>
            <p>When ordering or registering on our site, as appropriate, you may be asked to 
                enter your: name, e-mail address or mailing address. You may, however, visit our site anonymously.</p>
            <h3>What do we use your information for?</h3>
            <p>Any of the information we collect from you may be used in one of the following ways:</p>
            <ul>
                <li>To personalize your experience (your information helps us to better respond to your individual needs)</li>
                <li>To improve our website (we continually strive to improve our website offerings based on the information and 
                    feedback we receive from you)</li>
                <li>To improve customer service (your information helps us to more effectively respond to your customer service 
                    requests and support needs) </li>
                <li>To process transactions</li>
                <ul>
                    <li>Your information, whether public or private, will not be sold, exchanged, transferred, or given to any 
                        other company for any reason whatsoever, without your consent, other than for the express purpose of delivering 
                        the purchased product or service requested.</li>
                </ul>
                <li>To administer a contest, promotion, survey or other site feature</li>
                <li>To send periodic emails </li>
                <ul>
                    <li>
                    The email address you provide for order processing, may be used to send you information 
                    and updates pertaining to your order, in addition to receiving occasional company news, updates, 
                    related product or service information, etc.
                    </li>
                </ul>
            </ul>

            <h3>How do we protect your information?</h3>
            <p>We implement a variety of security measures to maintain the safety of your personal information when you place 
                an order or enter, submit, or access your personal information.</p>
            <p>We offer the use of a secure server. All supplied sensitive information is transmitted via 
                Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers 
                database only to be accessible by those authorized with special access rights to such systems, 
                and are required to keep the information confidential.</p>

            <h3>Do we use cookies?</h3>
            <p>Yes (Cookies are small files that a site or its service provider transfers to your computers hard drive 
                through your Web browser (if you allow) that enables the sites or service providers systems to recognize 
                your browser and capture and remember certain information).</p>
            <p>We use cookies to help us remember and process the items in your shopping cart, understand and save your 
                preferences for future visits and keep track of advertisements.</p>

            <h3>Do we disclose any information to outside parties?</h3>
            <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. 
                This does not include trusted third parties who assist us in operating our website, conducting our business, 
                or servicing you, so long as those parties agree to keep this information confidential. We may also release your 
                information when we believe release is appropriate to comply with the law, enforce our site policies, or protect 
                ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided 
                to other parties for marketing, advertising, or other uses.</p>

            <h3>Third Party Links</h3>
            <p>Occasionally, at our discretion, we may include or offer third party products or services on our website. 
                These third party sites have separate and independent privacy policies. We therefore have no responsibility 
                or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity 
                of our site and welcome any feedback about these sites.</p>

            <h3>Online Privacy Policy Only</h3>
            <p>This online privacy policy applies only to information collected through our website and not to information collected 
                offline.</p>

            <h3>Your Consent</h3>
            <p>By using our site, you consent to our privacy policy.</p>

            <h3>Changes to Our Privacy Policy</h3>
            <p>If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy Policy 
                modification date below.</p>
            <p>This policy was last modified on 01.01.2017</p>

            </div>

            <div class='row-fluid-content'>

            <div class='return-content'>

            <h1><span>Return Policy</span></h1>
            <p>Your satisfaction is guaranteed.</p>
            <p>If you are not satisfied with your purchase, please send an email support@tnbglasses.com or call TNB Glasses 
                Customer Care at 1-844-387-4289 for a Return Authorization (RA) number within 30 days of receipt of product.</p>
            <p>The product must be returned within 10 calendar days of the issuance of the RA number. All products must be packed 
                in the original packaging including any accessories, manuals, documentation and registration that were included 
                with the product.</p>
            <p>If the item is returned in the original condition, we will exchange it or provide you with a refund based on your 
                original method of payment. Returns that do not meet this condition may be subject to a restocking fee.</p>

            <h2>RETURN PROCESS</h2>
            <p>Follow these simple steps for a prompt refund:</p>
            <p>1. Obtain a Return Authorization number by calling Customer Care at 1-844-387-4289; please have your invoice or 
                packing list handy when placing the call.</p>
            <p>2. Securely package item(s) in the original packaging (including any documentation, manuals, warranty information, 
                registration, etc. that were received along with the product).</p>
            <p>3. Include the packing list in the box & please write the RA number on the outside of package.</p>
            <p>4. Depending on your location, they will either be able to pick up the order from your house or you will be able to 
                drop it off at one of their drop off locations.More detailed instructions will be provided during TNB Glasses 
                Customer Care Call</p>
            <p>5. Once your return is received and verified, we will credit you in the same form of the original payment for 
                the price of the item(s) usually within 15 business days of receipt.</p>

            <h2>DISCLAIMERS</h2>
            <h3>RETURN AUTHORIZATION (RA) NUMBER REQUIRED</h3>
            <p>All products being returned must have a Return Authorization number in order for a prompt refund. Product must be 
                returned within 10 days of issuance of Return Authorization number. Original freight charges (if any) are not 
                refundable.</p>
            
            <h3>30-DAY RETURN PERIOD</h3>
            <p>You must request a Return Authorization number within 30 days of the date of original purchase. Securely package items 
                as we are not responsible for any items lost or damaged in transit.</p>

            <h3>MISSING ITEM OR DAMAGED PRODUCT FEE</h3>
            <p>Additional fees may be charged for any product missing the original box, packaging material, contents, 
                accessories, and/or manuals (i.e. any product not in "sellable" condition).</p>
            <p>Note: We only accept returns on items purchased from TNBGlasses.com. Items purchased from any TNB Glasses partners 
                retailer directly must be returned to the place of purchase.</p>
            </div>

            </div>
        </div>

        <>
        </> 
      </div>
    );
  };

  export default Policies;