import React from 'react';
import '../../App.css';
import Cards from '../Card';
import HeroSection from '../HeroSection';
import Footer from '../Footer';

function Home() {
  return (
    <div class='container-fluid content-grid'>
      <>
      <HeroSection />
      <Cards />
      </> 
    </div>
  );
};

export default Home;