import React from 'react';
import './Profile.css';

 function Profile() {  
    return (
      <div>
        <>
        <div class='profile .a-grid-center .a-grid-vertical-align'>
          {/*Have account image
          name
          location
          email
          address (billing/shipping) - be able to have multiple, set default
          edit buttons
          */}
          {/* <div class="left-space span-3 spacing" ></div> */}
          <div class="profile-content">

            <div class="a-box a-color-alternate-background"><div class="a-box-inner a-padding-medium">   
              <div class="a-section a-spacing-none a-spacing-top-micro a-padding-none profileSectionStyling">
                <div class="a-row a-spacing-none a-ws-row">
                  <div id="profile-name-section" class="a-section a-spacing-top-extra-large name-edit-section-left">
                      <h1 id="profile-name" class="a-size-large aok-break-word a-text-bold">
                        John Doe
                      </h1>
                                             
                      <span class="a-declarative" data-action="a-modal" data-csa-c-type="widget" data-csa-c-func-deps="aui-da-a-modal" data-a-modal="{&quot;padding&quot;:&quot;none&quot;,&quot;name&quot;:&quot;editProfileNameModal&quot;,&quot;width&quot;:&quot;500&quot;,&quot;header&quot;:&quot;Edit your name&quot;}" id="name-edit-button" data-csa-c-id="wsregd-5xojyl-pvhimd-rzkjtn">
                        <a id="name-edit-modal-link" class="a-link-normal" href="#">
                          <img alt="Link to edit profile name" src="https://m.media-amazon.com/images/G/01/Beerus-MY/pencil.png" class="name-edit-icon" id="name-edit-pencil-image" />
                        </a>
                      </span>
                  </div>
                </div>
                
                <div class="a-row a-spacing-none a-ws-row">
                  <h1 id="profile-type" class="a-size-base a-spacing-none a-color-tertiary a-text-bold">
                    Account holder
                  </h1>        
                </div>
                </div>
              </div>
            </div>

            <div id="profile-contact-details-card" class="a-box a-spacing-top-base"><div class="a-box-inner">
              <h1 id="contact-details-heading" class="a-size-medium a-spacing-small a-text-bold">
                Contact Details
              </h1>
              <p id="contact-details-subtext" class="a-spacing-medium a-color-secondary">
                Receive important alerts for your profile here.
              </p>
              <div class="a-row a-grid-vertical-align a-grid-center a-ws-row">
                <div class="a-column a-span11">
                  <p id="contact-details-mobile-number" class="a-spacing-mini a-text-bold">
                    Email
                  </p>
              </div>
              <div class="a-column a-span1 a-text-right a-span-last">
                <form method="post" action="/manage-your-profiles/edit-phone-number" id="phone-number-edit-form">
                  <input type="hidden" name="pid" value="amzn1.account.AF6FPPVVAM4A4RKOLRARPZZWI43Q" id="edit-phone-number-directed-pid" />
                  <input type="hidden" name="pinStatus" value="false" id="edit-phone-number-pin-status"/>
                  <span class="a-declarative" data-action="edit-phone-number" data-csa-c-type="widget" data-csa-c-func-deps="aui-da-edit-phone-number" data-edit-phone-number="{}" data-csa-c-id="bbxf6q-y1dmdw-mdqtq4-8tayjx">
                    <a id="mobile-number-edit-link" class="a-link-normal linkStylingHover" href="#">
                      <img alt="Link to update mobile number" src="https://m.media-amazon.com/images/G/01/Beerus-MY/pencil.png" class="name-edit-icon" />
                    </a>
                  </span>
                  <input type="hidden" name="anti-csrftoken-a2z" value="hHwayX+pQOOYr5//LlYa8LpWX7J7RPQYsEJVu2JJpGmMAAAAAGUgkHkAAAAB" />
                </form>
                </div>
              </div>
              
              <p id="mobile-number" class="a-color-tertiary">
                Not set
              </p>
              </div>
            </div>

            <div id="profile-settings-card" class="a-box a-spacing-top-base"><div class="a-box-inner">
              <h1 id="profile-settings-heading" class="a-size-medium a-spacing-small a-text-bold">
                Settings
              </h1>
              <p id="profile-settings-subtext" class="a-spacing-medium a-color-secondary">
                Optional PIN for additional security.
              </p>
              <div class="a-row a-grid-vertical-align a-grid-center a-ws-row">
                <div class="a-column a-span11">
                  <p id="settings-pin" class="a-spacing-mini a-text-bold">
                    PIN
                  </p>
                </div>
              <div class="a-column a-span1 a-text-right a-span-last">
                <form method="post" action="/manage-your-profiles/edit-pin" id="pin-edit-form">
                  <input type="hidden" name="pid" value="amzn1.account.AF6FPPVVAM4A4RKOLRARPZZWI43Q" id="edit-pin-directed-pid"/>
                  <input type="hidden" name="pinStatus" value="false" id="edit-pin-pin-status"/>
                  <span class="a-declarative" data-action="edit-pin" data-csa-c-type="widget" data-csa-c-func-deps="aui-da-edit-pin" data-edit-pin="{}" data-csa-c-id="26l11v-231vsv-wvc45v-kloqrd">
                    <a id="edit-pin-link" class="a-link-normal" href="#">
                        <img alt="Link to update PIN" src="https://m.media-amazon.com/images/G/01/Beerus-MY/pencil.png" class="name-edit-icon"/>
                    </a>
                  </span>
                  <input type="hidden" name="anti-csrftoken-a2z" value="hHwayX+pQOOYr5//LlYa8LpWX7J7RPQYsEJVu2JJpGmMAAAAAGUgkHkAAAAB"/>
                </form>
              </div>
            </div>
            <p id="pin-status" class="a-color-tertiary">
              Not set
            </p>
            </div>
          </div>
          </div>

        </div>
        </> 
      </div>
    );
   };

  export default Profile;